import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  HostListener,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
})
export class DynamicTableComponent implements OnInit {
  @Input() tableConfig: any[] = [];
  @Input() defaultEmptyMsg = 'Untitle';
  @Input() jsonData: any[] = [];
  @Input() deleteAction: boolean = false;
  @Input() editAction: boolean = false;
  @Input() disabledActions: boolean = false;
  @Input() showCurrentEdit: boolean = false;
  @Input() currentEditIndex: number = 0;

  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<any> = new EventEmitter();

  menuOpenIndex: number | null = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private eRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.cdr.detectChanges();
  }

  handleDelete(index: number, item: any) {
    this.onDelete.emit({ index, item });
  }

  handleEdit(index: number, item: any) {
    this.onEdit.emit({ index, item });
  }

  toggleMenu(index: number) {
    this.menuOpenIndex = this.menuOpenIndex === index ? null : index;
  }

  isMenuOpen(index: number): boolean {
    return this.menuOpenIndex === index;
  }

  emitChanges() {
    this.cdr.detectChanges();
  }
  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.menuOpenIndex = null;
      this.cdr.detectChanges();
    }
  }
}
