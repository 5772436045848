<div class="footer no-print bg-black text-white pt-2">
  <span>
    Developed by PavCon, LLC. &#169; {{ year }}. All Rights Reserved |
    {{ version }}</span>
</div>
<div class="footer print">
  <div class="distribution-statement text-xs text-balance">
    Distribution Statement D. Distribution authorized to Department of Defense and U.S. DoD
    contractors only.
    PavCon, LLC. All rights reserved. &copy; {{year}}.
    WARNING: This file may contain export-controlled information.
  </div>
</div>