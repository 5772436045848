import {
  Component,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import { AppPopupService } from '../../services/app-popup.service';
import { SubscriptionHelper } from '../../helpers/subscription.helper';

@Component({
  selector: 'app-popup',
  templateUrl: './app-popup.component.html',
  styleUrls: ['./app-popup.component.scss'],
})
export class AppPopupComponent implements AfterViewChecked, OnDestroy {
  showPopup = false;
  message = '';
  title = '';
  type = '';
  actionText = '';

  constructor(
    private popupService: AppPopupService,
    private subsMgr: SubscriptionHelper,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.subsMgr.add(
      this.popupService.popupData$.subscribe((data) => {
        this.showPopup = data.showPopup;
        this.message = data.message;
        this.title = data.title;
        this.type = data.type;
        this.actionText = data.actionText;
        this.cd.detectChanges();
      })
    );
  }

  doAction(): void {
    this.popupService.actionEvent.emit();
    this.showPopup = false;
  }

  ngOnDestroy(): void {
    this.subsMgr.destroy();
  }

  dismiss(): void {
    this.showPopup = false;
  }
}
