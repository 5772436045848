<div class="container-body">
  <mat-card class="full-height">
    <mat-card-header>
      <div class="header-content">
        <button mat-icon-button (click)="goHome()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-card-title>Settings</mat-card-title>
      </div>
    </mat-card-header>
    <mat-card-content>
      <!-- <div class="section" *ngIf="isDevEnvironment">
        <div class="header-container">
          <div class="title">Developers Mode</div>
          <mat-slide-toggle class="toggle" [(ngModel)]="isDeveloperMode" color="primary">ON/OFF</mat-slide-toggle>
          </div>
        <div class="settings-container">
          <div *ngIf="isDeveloperMode" class="action-buttons">
            <button mat-raised-button color="primary" (click)="enterCode()">Enter Code</button>
            <button mat-raised-button color="warn" (click)="clearData()">RESET</button>
          </div>
        </div>
      </div> -->

      <div class="section">
        <div class="settings-container">
          <div class="settings-container-row">
            <div class="title">Kiosk Mode</div>
            <mat-slide-toggle
              class="toggle"
              [(ngModel)]="isKioskMode"
              color="primary"
              (change)="onKioskModeChange()"
              >OFF/ON</mat-slide-toggle
            >
          </div>
          <span class="instrucctions"
            >In this mode, upon submitting the survey, the screen will
            automatically refresh to display the same survey, making it ready
            for inmmediate reuse.</span
          >

          <hr />
          <button
            mat-raised-button
            color="warn"
            (click)="clearData()"
            *ngIf="isKioskMode"
          >
            Reset Data
          </button>
        </div>
      </div>

      <div class="section app-info-section">
        <div class="header-container">
          <div class="title">App Info</div>
        </div>
        <div class="info-container">
          <div class="info-item">
            Version: <b>{{ version }} </b>
          </div>
          <div class="info-item">
            Environment: <b>{{ env }} </b>
          </div>
          <hr />
          <div class="info-item mt-5">Release Notes: None</div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
