import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(value: number, ...args: any[]): string {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return '';
    } else {
      return date.toLocaleDateString();
    }
  }
}
