import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-status-box-modal',
  templateUrl: './status-box-modal.component.html',
  styleUrl: './status-box-modal.component.scss',
})
export class StatusBoxModalComponent {
  constructor(
    public dialogRef: MatDialogRef<StatusBoxModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onClose() {
    this.dialogRef.close();
  }
}
