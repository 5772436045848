<!-- header.component.html -->

<header class="bg-header dark:bg-dark-header text-white shadow w-full dark:border-dark-header  dark:border-b" *ngIf="isLoaded">
  <div class="flex justify-between items-center h-16 px-2 sm:px-4 lg:px-6 w-full">
    <div class="flex mr-2 flex-shrink-0 flex-row gap-1  px-4 rounded-md border-gray-50">
      <img
      class="logo"
      alt="OE"
      src="./../../../assets/logos/oe logo.png"
      priority
    />
    <div class="title-container">
      <span class="text-lg text-black font-bold">OSD WARFIGHTERS</span>
      <span class="sub-title text-black font-light">Touchpoint</span>
    </div>
    </div>

    <div class="ml-4 flex flex-row items-end gap-3 md:ml-6">

        <button class="secondary-button flex flex-row gap-2 items-center justify-center h-[35px]" (click)="close()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 ">
            <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
          </svg>

          <span>Close Preview</span></button>

    </div>
  </div>
</header>

<iframe [src]="safeUrl" (load)="onIframeLoad()" width="100%" height="100%"></iframe>
