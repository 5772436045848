import { Injectable } from '@angular/core';
// import { environment } from 'src/environments/environment';
import Pica from 'pica';

@Injectable({
  providedIn: 'root',
})
export class ImageProcessingService {
  private pica = Pica();

  constructor() {}

  async resizeImage(
    file: File,
    maxWidth: number,
    maxHeight: number,
    callback: (resizedImageSrc: string, blob: Blob) => void
  ): Promise<void> {
    try {
      // if (environment.name === 'dev') {
      //   console.info(`Original File Size: ${this.formatBytes(file.size)}`);
      // }

      const result = await this.readAsDataURL(file);
      const img = await this.loadImage(result);

      // const canvas = document.createElement('canvas');
      const targetCanvas = document.createElement('canvas');
      targetCanvas.width = maxWidth;
      targetCanvas.height = maxHeight;

      // Use Pica to resize the image.
      await this.pica.resize(img, targetCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      });

      // Convert the resized canvas to a Blob.
      const blob = await this.canvasToBlob(targetCanvas, 'image/png', 0.5);

      if (blob) {
        // if (environment.name === 'dev') {
        //   console.info(`Processed Blob Size: ${this.formatBytes(blob.size)}`);
        // }
        callback(result, blob);
      } else {
        console.error('Failed to create a Blob from canvas');
      }
    } catch (error) {
      console.error('Error processing image:', error);
    }
  }

  private readAsDataURL(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  private loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  private canvasToBlob(
    canvas: HTMLCanvasElement,
    type: string,
    quality: number
  ): Promise<Blob | null> {
    return new Promise((resolve) => {
      canvas.toBlob(resolve, type, quality);
    });
  }

  private resizeCanvas(
    image: HTMLImageElement,
    maxWidth: number,
    maxHeight: number
  ): HTMLCanvasElement {
    const ratio = Math.min(maxWidth / image.width, maxHeight / image.height);
    const newWidth = image.width * ratio;
    const newHeight = image.height * ratio;
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
    } else {
      throw new Error('Failed to get drawing context from canvas');
    }
    return canvas;
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
