<div *ngFor="let option of field.options; index as i" class="flex items-center"
  [ngClass]="{'pointer-events-none opacity-50': disabled}">
  <input type="checkbox" [id]="'checkbox-' + field.fieldId + '-' + i" [value]="option"
    (change)="handleMultipleChoiceChange(option, $event)" [checked]="field.value && field.value.includes(option)"
    class="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
    [attr.name]="'field-' + field.fieldId" [disabled]="disabled" />
  <label [for]="'checkbox-' + field.fieldId + '-' + i" class="ml-2 text-sm text-gray-700">
    {{ option }}
  </label>
</div>
