import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { HotToastService } from '@ngxpert/hot-toast';
import { FormField } from '../../entities/form.entity';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-field-file-upload',
  templateUrl: './field-file-upload.component.html',
  styleUrls: ['./field-file-upload.component.scss'],
})
export class FieldFileUploadComponent implements OnChanges {
  @Input() field!: FormField;
  @Input() disabled: boolean = false;
  @Output() onFieldChange = new EventEmitter<any>();

  extensions: string[] = [];
  fileName: string = '';
  fileBuffer: ArrayBuffer | null = null;

  constructor(private hotToastService: HotToastService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['field'] && changes['field'].currentValue) {
      if (this.field.metadata.acceptedExtensions) {
        this.extensions = this.field.metadata.acceptedExtensions.map(
          (ext) => '.' + ext.replace(/^\./, '')
        );
      }

      if (this.field.value && this.field.value.fileName) {
        this.fileName = this.field.value.fileName;
      }
    }
  }

  fileChange(event: Event): void {
    const element = event.target as HTMLInputElement;
    const file = element.files ? element.files[0] : null;

    if (file) {
      this.validateAndReadFile(file);
    }
  }

  validateAndReadFile(file: File): void {
    const fileExtension = '.' + file.name.split('.').pop()?.toLowerCase();
    if (!this.extensions.includes(fileExtension)) {
      this.fileName = '';
      this.fileBuffer = null;
      this.hotToastService.error(
        'Invalid file type. Accepted types: ' + this.extensions.join(', ')
      );
      return;
    }

    this.fileName = file.name;
    const reader = new FileReader();
    reader.onload = () => {
      this.fileBuffer = reader.result as ArrayBuffer;
      this.emitFileChange();
    };
    reader.onerror = () => {
      this.hotToastService.error('Error reading file.');
    };
    reader.readAsArrayBuffer(file);
  }

  emitFileChange(): void {
    if (this.fileBuffer) {
      const mimeType = this.getMimeType(this.fileName);
      const base64File = this.convertToBase64(this.fileBuffer);
      const fileExtension = '.' + this.fileName.split('.').pop()?.toLowerCase();
      const key = uuid();
      const keyFile = `${key}${fileExtension}`;

      this.onFieldChange.emit({
        isFile: true,
        fieldId: this.field.fieldId,
        isValid: this.fileName !== '' && this.fileBuffer !== null,
        value: {
          key,
          keyFile,
          mimeType,
          fileName: this.fileName,
          base64File,
          isValid: this.fileName !== '' && this.fileBuffer !== null,
        },
      });
    } else {
      this.onFieldChange.emit({
        fieldId: this.field.fieldId,
        isValid: false,
        value: null,
      });
    }
  }

  convertToBase64(buffer: any) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }


  getMimeType(filename: string): string {
    const mimeTypes: { [key: string]: string; } = {
      '.jpg': 'image/jpeg',
      '.jpeg': 'image/jpeg',
      '.png': 'image/png',
      '.pdf': 'application/pdf',
      '.doc': 'application/msword',
      '.docx':
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      '.xls': 'application/vnd.ms-excel',
      '.xlsx':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      '.ppt': 'application/vnd.ms-powerpoint',
      '.pptx':
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      '.txt': 'text/plain',
    };

    const extension = filename
      .slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase();
    return mimeTypes['.' + extension] || 'application/octet-stream';
  }
}
