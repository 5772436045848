import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProgressState,
  UploadProgressService,
} from '../../services/upload-progress.service';

@Component({
  selector: 'app-upload-progress-modal',
  templateUrl: './upload-progress-modal.component.html',
  styleUrls: ['./upload-progress-modal.component.scss'],
})
export class UploadProgressModalComponent implements OnInit {
  progressState$!: Observable<ProgressState>;
  currentProgress: ProgressState = {
    totalTasks: 0,
    completedTasks: 0,
    totalProgress: 0,
    filesProgress: {},
  };
  message = '';
  completedMessage = '';
  countdown: number = 60;
  countdownInterval!: any;

  constructor(
    private uploadProgressService: UploadProgressService,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<UploadProgressModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.completedMessage = data.completedMessage;
    this.message = data.message;
  }

  ngOnInit(): void {
    this.progressState$ = this.uploadProgressService.progressState$;

    this.progressState$.subscribe((progressState) => {
      this.currentProgress = progressState;
      if (progressState.completedTasks === progressState.totalTasks) {
        this.currentProgress.totalProgress = 100;
        this.startCountdown();
      } else {
        this.currentProgress.totalProgress =
          (progressState.completedTasks / progressState.totalTasks) * 100;
      }
      this.cdr.detectChanges();
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  private startCountdown(): void {
    this.countdownInterval = setInterval(() => {
      this.countdown -= 1;
      if (this.countdown === 0) {
        clearInterval(this.countdownInterval);
        this.dialogRef.close();
      }
    }, 1000);
  }
}
