import { Component, ContentChild, ElementRef, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss'
})
export class MultiSelectComponent {
  @Input() label: string = '';
  @Input() options: any[] = [];
  @Input() labelKey: string = 'value';
  @Input() valueKey: string = 'uuid';
  @Input() boxSize: string = '200';
  @Input() class!: string;

  @Output() onSelect = new EventEmitter<any[]>();

  @ContentChild('extraContent', { static: false }) extraContentTemplate!: TemplateRef<any>;


  selected: any[] = [];
  filteredOptions: any[] = [];
  searchTerm: string = '';
  showDropdown: boolean = false;
  foundCount: number| null = 0;

  constructor(private eRef: ElementRef) {
    this.filteredOptions = [...this.options];
  }

  ngOnChanges() {
    this.filterOptions();
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
  clearSelection() {
    this.selected = [];
    this.searchTerm = '';
    this.filterOptions();

  }

  filterOptions() {
    const searchTermLower = this.searchTerm.toLowerCase();
    this.filteredOptions = orderBy(
      this.options.filter(option =>
        option[this.labelKey].toLowerCase().includes(searchTermLower)
      ),
      [this.labelKey.toLowerCase()],
      ['asc']
    );
  }

  selectOption(option: any) {
    const index = this.selected.findIndex(sel => sel[this.valueKey] === option[this.valueKey]);
    if (index > -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(option);
    }
    this.onSelect.emit(this.selected);
  }

  isSelected(option: any): boolean {
    return this.selected.findIndex(sel => sel[this.valueKey] === option[this.valueKey]) > -1;
  }
  @HostListener('document:click', ['$event'])
  clickout(event:any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }

}
