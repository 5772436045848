import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-single',
  templateUrl: './select-single.component.html',
  styleUrls: ['./select-single.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSingleComponent),
      multi: true,
    },
  ],
})
export class SelectSingleComponent implements ControlValueAccessor {
  @Input() options: any[] | null = null;
  @Input() keyLabel: string | null = null;
  @Input() keyValue: string | null = null;
  @Input() labelInSelect: boolean = false;
  @Input() label: string = '';
  @Input() class: string | null = null;
  @Input() buttonClass: string | null = null;
  @Input() capitalized: boolean = true;

  @Output() selectionChange = new EventEmitter<any>();

  selectedOption: any;
  dropdownOpen = false;

  onChange = (option: any) => {};

  onTouched = () => {};

  writeValue(obj: any): void {
    this.selectedOption = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  selectOption(option: any): void {
    this.selectedOption = option;
    this.onChange(option);
    this.selectionChange.emit(option);
    this.dropdownOpen = false;
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getOptionLabel(option: any): string {
    return this.keyLabel && option ? option[this.keyLabel] : option;
  }

  isOptionSelected(option: any): boolean {
    return this.selectedOption === option;
  }

  getDisplayText(): string {
    if (!this.selectedOption) return 'Select option';
    return this.getOptionLabel(this.selectedOption);
  }

  replace(value: string = '') {
    if (value) return value.replace('-', ' ');
    return '';
  }
}
