export const environment = {
  production: false,
  name: 'dev',
  projectName: `WARFIGHTERS`,
  defaultTheme: 'WARFIGHTERS',
  version: '2.0.0',
  region: 'us-gov-west-1',
  root: 'http://localhost:4200/',
  url: 'https://dev-survey.warfighterfirst.org',
  endpoint: 'https://dev-be-survey.warfighterfirst.org',
  refreshEndpoint: 'https://dev-be.cristlgov.com/auth/refreshToken',
  sandboxEnabled: false,
  uamUrl: 'https://dev.cristlgov.com',
  cookieInfo: {
    domain: '.cristlgov.com',
  },
  amplify: {
    Auth: {
      identityPoolId: 'us-gov-west-1:045de7ba-c8f7-4f75-bcf7-8c11ac85d2cb',
      region: 'us-gov-west-1',
      userPoolId: 'us-gov-west-1_qCxXRs6hT',
      userPoolWebClientId: '2as297lmbnd4ho7muephmtn4jk',
    },
  },
};
