import { Component, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() text = '';
  @Input() position: { top: string; left: string; } = {
    top: '0px',
    left: '0px',
  };

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.position = 'absolute';
    this.elementRef.nativeElement.style.top = this.position.top;
    this.elementRef.nativeElement.style.left = this.position.left;
  }
}
