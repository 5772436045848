import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortDate',
})
export class ShortDatePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, ...args: any[]): any {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    } else {
      return date.toLocaleDateString();
    }
  }
}
