import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormField } from '../../entities/form.entity';
import flatpickr from 'flatpickr';

@Component({
  selector: 'app-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['./field-date.component.scss'],
})
export class FieldDateComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;
  @Input() field!: FormField;
  @Output() onFieldChange = new EventEmitter<any>();
  @Input() disabled: boolean = false;

  private picker!: flatpickr.Instance;
  isValid!: boolean;

  ngOnInit(): void {
    if (!this.field.value && this.field.metadata?.dateLimit) {
      this.field.value = new Date().toISOString().substring(0, 10);
    }
  }

  ngAfterViewInit(): void {
    const config: flatpickr.Options.Options = {
      dateFormat: 'Y-m-d',
      defaultDate: this.field.value || new Date(),
      minDate: this.getMinDate(),
      maxDate: this.getMaxDate(),
      onChange: this.handleDateChange.bind(this),
    };

    this.picker = flatpickr(this.inputElement.nativeElement, config);
  }

  ngOnDestroy(): void {
    if (this.picker) {
      this.picker.destroy();
    }
  }

  private getMinDate(): string | undefined {
    if (this.field.metadata?.dateLimit === 'future-only') {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today.toISOString().substring(0, 10);
    }
    return undefined;
  }

  private getMaxDate(): string | undefined {
    if (this.field.metadata?.dateLimit === 'past-only') {
      const today = new Date();
      today.setHours(23, 59, 59, 999);
      return today.toISOString().substring(0, 10);
    }
    return undefined;
  }

  private handleDateChange(
    selectedDates: Date[],
    dateStr: string,
    instance: flatpickr.Instance
  ): void {
    this.field.value = dateStr;
    this.emitChanges();
  }

  private emitChanges(): void {
    this.onFieldChange.emit({
      fieldId: this.field.fieldId,
      isValid: this.isValidDate(this.field.value),
      value: this.field.value,
    });
  }

  private isValidDate(dateStr: string): boolean {
    return !!dateStr;
  }
}
