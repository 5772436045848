import {
  Component,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ImageProcessingService } from '../../services/image-processing.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrl: './image-upload.component.scss',
})
export class ImageUploadComponent implements OnChanges {
  @Input() initialImageUrl!: string;
  @Input() widthClass: string = 'w-64';
  @Input() heightClass: string = 'h-64';
  @Input() readonly: boolean = false;
  @Output() completed = new EventEmitter<ArrayBuffer>();

  imageUrl?: string | ArrayBuffer | null = null;
  static idCounter = 0;
  inputId: string;
  isAssetLoading = false;
  private shouldProcessFile = false;

  constructor(
    private imageProcessingService: ImageProcessingService,
    private cdr: ChangeDetectorRef
  ) {
    this.inputId = `file-input-${ImageUploadComponent.idCounter}`;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialImageUrl'] && !this.shouldProcessFile) {
      const newImageUrl = changes['initialImageUrl'].currentValue;
      if (newImageUrl !== this.imageUrl && newImageUrl.length) {
        this.imageUrl = newImageUrl;
        this.cdr.detectChanges();
      }
    }
  }

  onFileDropped(event: any) {
    this.processFile(event[0]);
  }

  onFileChanged(event: any) {
    this.shouldProcessFile = true;
    this.processFile(event.target.files[0]);
  }
  private processFile(file: File) {
    if (!this.shouldProcessFile) {
      return;
    }
    this.isAssetLoading = true;
    this.imageProcessingService.resizeImage(
      file,
      800,
      600,
      (resizedImageSrc, blob) => {
        this.imageUrl = resizedImageSrc;
        this.emitImage(resizedImageSrc, blob);
        this.cdr.detectChanges();
      }
    );
  }

  private emitImage(data: any, blob: Blob) {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        this.completed.emit(data);
        URL.revokeObjectURL(this.imageUrl as string);
        this.isAssetLoading = false;
      }
    };
    reader.readAsArrayBuffer(blob);
  }
}
