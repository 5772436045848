import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface Theme {
  [key: string]: string;
}

interface ThemesResponse {
  colors: { [themeName: string]: Theme };
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeClass = 'dark';

  constructor(private http: HttpClient) {
    this.loadInitialTheme();
  }

  toggleTheme(): void {
    this.isDarkTheme() ? this.activateLightMode() : this.activateDarkMode();
  }

  activateDarkMode(): void {
    this.setDarkTheme(true);
  }

  activateLightMode(): void {
    this.setDarkTheme(false);
  }

  private setDarkTheme(isDark: boolean): void {
    document.body.classList.toggle(this.themeClass, isDark);
    localStorage.setItem('theme', JSON.stringify(isDark));
  }

  loadInitialTheme(): void {
    const storedTheme = localStorage.getItem('theme');
    const isDark = storedTheme ? JSON.parse(storedTheme) : false;
    this.setDarkTheme(isDark);
  }

  applyThemeByName(themeName: string): void {
    this.getThemes().subscribe((themesResponse) => {
      const themes = themesResponse.colors;
      const theme = themes[themeName] ?? themes[environment.defaultTheme];
      if (theme) {
        this.applyTheme(theme);
        localStorage.setItem(`${environment.projectName}.theme`, themeName);
      }
    });
  }

  private applyTheme(theme: Theme): void {
    Object.entries(theme).forEach(([property, value]) => {
      document.documentElement.style.setProperty(`--${property}`, value);
    });
  }

  getThemes(): Observable<ThemesResponse> {
    return this.http.get<ThemesResponse>('/assets/template/colors.json');
  }

  getCurrentThemeName(): string {
    return (
      localStorage.getItem(`${environment.projectName}.theme`) ||
      environment.defaultTheme
    );
  }

  isDarkTheme(): boolean {
    return document.body.classList.contains(this.themeClass);
  }
}
