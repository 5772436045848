import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
// import { Store } from '@ngrx/store';
import { SubscriptionHelper } from 'src/app/shared/helpers/subscription.helper';
import { AppUserService } from 'src/app/shared/services/app-user.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements AfterViewInit {
  @Input() isSlimMode = true;
  userHaveRole = false;
  isAdmin = false;
  currentUser: any = {};

  navigation = [
    {
      name: 'Home',
      icon: 'home',
      routes: ['/admin'],
    },
    {
      name: 'Surveys Builder',
      icon: 'clipboard-list',
      routes: ['/admin/surveys', '/admin/survey'],
    },
    {
      name: 'Forms Builder',
      icon: 'hammer',
      routes: ['/admin/form-builder', '/admin/form-editor'],
    },
    {
      name: 'Assignments',
      icon: 'shapes',
      routes: ['/admin/asssignments', '/admin/asssignments/workarea'],
    },
    {
      name: 'Sessions',
      icon: 'calendar-days',
      routes: ['/admin/sessions'],
    },
    {
      name: 'Invite List',
      icon: 'contact',
      routes: ['/admin/invitations'],
    },
    {
      name: 'Insights',
      icon: 'area-chart',
      routes: ['/admin/results'],
    },
  ];

  private screenThreshold: number = 1200;
  private subsManager = new SubscriptionHelper();

  constructor(
    private appUserService: AppUserService,

    private router: Router,
    private cdr: ChangeDetectorRef
  ) { }
  ngAfterViewInit(): void {
    this.loadUserDetails();
  }

  loadUserDetails() {
    this.appUserService
      .getUserDetails()
      .then((userDetails: any) => {
        if (userDetails && userDetails['custom:role']) {
          this.currentUser = userDetails;
          this.userHaveRole = true;
          if (userDetails['custom:role'] === 'organization-admin') {
            this.isAdmin = true;
          }
        }
      })
      .catch((error: any) => {
        console.error(error);
      });
  }
  isActive(paths: string[]): boolean {
    if (paths[0] === '/admin') {
      return false;
    }
    return paths.some((path) => this.router.url.includes(path));
  }
  isModuleDisabled() {
    return !this.userHaveRole && !this.isAdmin;
  }
  onNavigationClick(nav: any) {
    if (nav.routes) {
      this.router.navigate([nav.routes[0]]);
    }
  }
}
