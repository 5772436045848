import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeToFrom',
})
export class TimeToFromPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    const now = new Date().getTime();
    const targetDate = new Date(value * 1000).getTime();
    const timeDiff = targetDate - now;

    const absSeconds = Math.abs(Math.floor(timeDiff / 1000));
    const absMinutes = Math.floor(absSeconds / 60);
    const absHours = Math.floor(absMinutes / 60);
    const absDays = Math.floor(absHours / 24);

    if (timeDiff < 0) {

      if (absSeconds < 60) {
        return `Expired ${absSeconds}sec ago`;
      }
      if (absMinutes < 60) {
        return `Expired ${absMinutes}min ago`;
      }
      if (absHours < 24) {
        return `Expired ${absHours}hr ago`;
      }
      return `Expired ${absDays}d ago`;
    } else {

      if (absSeconds < 60) {
        return `${absSeconds}sec left`;
      }
      if (absMinutes < 60) {
        return `${absMinutes}min left`;
      }
      if (absHours < 24) {
        return `${absHours}hr left`;
      }
      return `${absDays}d left`;
    }
  }
}
