import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Task } from '../entities/survey.entity';

export interface ProgressState {
  totalProgress: number;
  completedTasks: number;
  totalTasks: number;
  filesProgress: Record<string, number>;
}

@Injectable({ providedIn: 'root' })
export class UploadProgressService {
  private progressStateSource = new BehaviorSubject<ProgressState>({
    totalTasks: 0,
    completedTasks: 0,
    totalProgress: 0,
    filesProgress: {},
  });

  public progressState$: Observable<ProgressState> =
    this.progressStateSource.asObservable();

  private totalTasks = 0;
  private completedTasks = 0;

  initializeProgress(tasks: Task[]): void {
    this.totalTasks = tasks.length;
    this.completedTasks = 0;
    const filesProgress: Record<string, number> = {};
    tasks.forEach((task) => {
      filesProgress[task.name] = 0;
    });

    this.progressStateSource.next({
      totalProgress: 0,
      filesProgress,
      totalTasks: this.totalTasks,
      completedTasks: this.completedTasks,
    });
  }

  updateFileProgress(fileId: string, progress: number): void {
    const currentState = this.progressStateSource.value;
    currentState.filesProgress[fileId] = progress;
    this.progressStateSource.next(currentState);
  }

  completeTask(): void {
    this.completedTasks++;
    this.updateTotalProgress();
  }
  private calculateProgress(): number {
    const progress = (this.completedTasks / this.totalTasks) * 100;
    return this.totalTasks > 0 ? Math.min(Math.round(progress), 100) : 0;
  }

  private updateTotalProgress(): void {
    const progress = this.calculateProgress();
    const currentState = this.progressStateSource.value;
    currentState.totalProgress = progress;
    currentState.completedTasks = this.completedTasks;
    this.progressStateSource.next(currentState);
  }

}
