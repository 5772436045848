<!-- <div class="app">
  <app-splash-screen></app-splash-screen>
  <div class="header-container">
    <app-header></app-header>
  </div>
  <div class="content" role="main">
    <router-outlet #outlet="outlet"></router-outlet>

    <div class="footer">
      <app-footer></app-footer>
    </div>
  </div>
  <app-popup></app-popup>
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div> -->

<app-splash-screen></app-splash-screen>
<router-outlet></router-outlet>
<app-popup></app-popup>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>