import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help-tooltip',
  templateUrl: './help-tooltip.component.html',
  styleUrl: './help-tooltip.component.scss'
})
export class HelpTooltipComponent {
  @Input() message!: string;
}
