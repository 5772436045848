import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: Date | string, ...args: unknown[]): string {
    const now = new Date();
    const past = new Date(value);
    const timeDiff = Math.abs(now.getTime() - past.getTime());
    const diffSeconds = Math.ceil(timeDiff / 1000);
    const diffMinutes = Math.ceil(diffSeconds / 60);
    const diffHours = Math.ceil(diffMinutes / 60);
    const diffDays = Math.ceil(diffHours / 24);

    if (diffSeconds < 60) {
      return `${diffSeconds} sec ago`;
    }

    if (diffMinutes < 60) {
      return `${diffMinutes} min ago`;
    }

    if (diffHours < 24) {
      return `${diffHours}hr ago`;
    }

    return `${diffDays}d ago`;
  }
}
