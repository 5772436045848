
<div class="print-preview">
  <header class="bg-header dark:bg-dark-header text-white shadow w-full dark:border-dark-header  dark:border-b no-print" >
  <div class="flex justify-between items-center h-16 px-2 sm:px-4 lg:px-6 w-full">
    <div class="flex mr-2 flex-shrink-0 flex-row gap-1  px-4 rounded-md border-gray-50">
      <img
      class="logo"
      alt="OE"
      src="./../../../assets/logos/oe logo.png"
      priority
    />
    <div class="title-container">
      <span class="text-lg text-black font-bold">OSD WARFIGHTERS</span>
      <span class="sub-title text-black font-light">Touchpoint</span>
    </div>
    </div>

    <div class="ml-4 flex flex-row items-end gap-3 md:ml-6">
      <button class="secondary-button flex flex-row gap-2 items-center justify-center h-[35px]" (click)="close()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 ">
          <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
        </svg>

        <span>Close</span></button>

        <button class="secondary-button flex flex-row gap-2 items-center justify-center h-[35px]" (click)="print()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
          </svg>


          <span>Print</span></button>

    </div>
  </div>
  </header>


<div class="print-body">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
</div>
