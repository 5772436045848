import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeUntil',
})
export class TimeUntilPipe implements PipeTransform {
  transform(value: number, ...args: unknown[]): string {
    const now = new Date().getTime();
    const future = new Date(value * 1000).getTime();
    const timeDiff = future - now;

    if (timeDiff < 0) {
      return 'EXPIRED';
    }

    const diffSeconds = Math.floor(timeDiff / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffSeconds < 60) {
      return `${diffSeconds} sec left`;
    }

    if (diffMinutes < 60) {
      return `${diffMinutes} min left`;
    }

    if (diffHours < 24) {
      return `${diffHours} hr left`;
    }

    return `${diffDays} d left`;
  }
}
