import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from '../../entities/form.entity';

@Component({
  selector: 'app-field-yesno',
  templateUrl: './field-yesno.component.html',
  styleUrls: ['./field-yesno.component.scss']
})
export class FieldYesnoComponent {
  @Input() field!: FormField;
  @Input() disabled: boolean = false;
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: string;
  }>();

  handleValueChange(value: string): void {
    this.field.value = value;
    this.emitFieldChange();
  }

  emitFieldChange(): void {
    this.onFieldChange.emit({
      fieldId: this.field.fieldId,
      isValid: this.validateField(this.field.value),
      value: this.field.value
    });
  }

  validateField(value: string): boolean {
    return value === 'yes' || value === 'no';
  }
}
