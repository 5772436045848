import { v4 as uuid } from 'uuid';
export class UUIDHelper {
  static generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  static generateShortUUID(): string {
    return uuid().replace(/-/g, '').toLowerCase();
  }
}
