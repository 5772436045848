import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit {
  @Output() toggled: EventEmitter<boolean> = new EventEmitter();
  @Input() value: boolean | null = null;

  ngOnInit(): void {
    if (this.value === null) {
      this.value = true;
    }
  }

  onToggle(event: Event): void {
    const checked = (event.target as HTMLInputElement).checked;
    this.value = checked;
    this.toggled.emit(checked);
  }
}
