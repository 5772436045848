import { Component, HostListener } from '@angular/core';
import { ThemeService } from './shared/services/theme.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private themeService: ThemeService) {}

  ngOnInit(): void {
    const themeName =
      this.themeService.getCurrentThemeName() || environment.defaultTheme;
    this.themeService.applyThemeByName(themeName);
  }

  prepareRoute(outlet: any) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrolled = window.scrollY;
    if (scrolled > 10) {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', '#ffd42f');
    } else {
      document
        .querySelector('meta[name="theme-color"]')
        ?.setAttribute('content', '#ffd42f');
    }
  }
}
