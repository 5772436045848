import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from '../../entities/form.entity';

@Component({
  selector: 'app-field-input-select',
  templateUrl: './field-input-select.component.html',
  styleUrls: ['./field-input-select.component.scss'],
})
export class FieldInputSelectComponent {
  @Input() field!: FormField;
  @Input() disabled: boolean = false;
  @Output() valueChange = new EventEmitter<{
    inputValue: string;
    selectValue: string;
  }>();
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: any;
  }>();

  inputValue: string = '';
  selectValue: string = '';
  isValid!: boolean;

  onInputChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.inputValue = target.value;
    this.emitValueChange();
  }

  onSelectChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.selectValue = target.value;
    this.emitValueChange();
  }

  emitValueChange(): void {
    this.isValid = this.validateValues();
    this.valueChange.emit({
      inputValue: this.inputValue,
      selectValue: this.selectValue,
    });
    this.onFieldChange.emit({
      fieldId: this.field.fieldId,
      isValid: this.isValid,
      value: {
        inputValue: this.inputValue,
        selectValue: this.selectValue
      }
    });
  }

  private validateValues(): boolean {
    return this.inputValue.trim() !== '' && this.selectValue !== '';
  }
}
