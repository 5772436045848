<div class="header-section">
  <!-- <button mat-icon-button (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
  </button> -->
  <nav class="breadcrumb">
    @for (
      item of breadcrumbItems;
      track i;
      let i = $index;
      let isLast = $last
    ) {
      @if (item.route) {
        <a
          [routerLink]="item.route"
          class="breadcrumb-item"
          [class.current]="isLast"
          >{{ item.label }}</a
        >
      }
      @if (!item.route && !isLast) {
        <span class="breadcrumb-item">{{ item.label }}</span>
      }
      @if (!item.route && isLast) {
        <span class="breadcrumb-item current">{{ item.label }}</span>
      }
      @if (!isLast) {
        <mat-icon class="breadcrumb-icon" *ngIf="!isLast"
          >chevron_right</mat-icon
        >
      }
    }
  </nav>
</div>
