import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from '../../entities/form.entity';

@Component({
  selector: 'app-field-multiple-choice',
  templateUrl: './field-multiple-choice.component.html',
  styleUrls: ['./field-multiple-choice.component.scss']
})
export class FieldMultipleChoiceComponent {
  @Input() field!: FormField;
  @Input() disabled: boolean = false;
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: any[];
  }>();

  handleMultipleChoiceChange(option: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (!this.field.value) {
      this.field.value = [];
    }

    if (isChecked && !this.field.value.includes(option)) {
      this.field.value.push(option);
    } else if (!isChecked && this.field.value.includes(option)) {
      this.field.value = this.field.value.filter((o: string) => o !== option);
    }

    this.emitFieldChange();
  }

  emitFieldChange(): void {
    this.onFieldChange.emit({
      fieldId: this.field.fieldId,
      isValid: this.validateField(),
      value: this.field.value,
    });
  }

  validateField(): boolean {
    return Array.isArray(this.field.value) && this.field.value.length > 0;
  }
}
