import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit{
  @Input() options: any[] | null = null;
  @Input() keyLabel: string | null = null;
  @Input() keyValue: string | null = null;
  @Input() multiSelect: boolean = false;
  @Input() labelInSelect: boolean = false;
  @Input() label: string = '';
  @Input() class: string | null = null;

  private selectedValueSubject = new BehaviorSubject<any>(null);
  private _selectedValue: any;

  @Input()
  set selectedValue(val: any) {
    this._selectedValue = val;
    this.selectedValueSubject.next(val);
  }

  get selectedValue(): any {
    return this._selectedValue;
  }

  @Output() selectionChange = new EventEmitter<any | any[]>();

  selectedOptions: any[] = [];
  dropdownOpen = false;

  ngOnInit() {
    this.selectedValueSubject.subscribe({
      next: (val) => {
        this.selectedOptions = this.multiSelect
          ? Array.isArray(val)
            ? [...val]
            : []
          : [val];
      }
    });
  }

  selectOption(option: any) {
    if (this.multiSelect) {
      const index = this.selectedOptions.findIndex(o => o === option);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      } else {
        this.selectedOptions.push(option);
      }
    } else {
      this.selectedOptions = [option];
      this._selectedValue = option;
    }
    this.selectionChange.emit(this.multiSelect ? this.selectedOptions : option);
    this.dropdownOpen = false;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getOptionLabel(option: any): string {
    return this.keyLabel && option ? option[this.keyLabel] : option;
  }

  isOptionSelected(option: any): boolean {
    return this.selectedOptions.includes(option);
  }

  getDisplayText(): string {
    if (!this.selectedOptions.length) return 'Select option';
    return this.selectedOptions.map(option => this.getOptionLabel(option)).join(', ');
  }

  replace(value: string) {
    return value.replace('-', ' ').toLowerCase();
  }
}
