import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-extension-input',
  templateUrl: './extension-input.component.html',
  styleUrl: './extension-input.component.scss'
})
export class ExtensionInputComponent {
  @Input() extensions: string[] = [];
  @Output() extensionsChange: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  currentInput: string = '';

  constructor() { }

  onEnter(value: string): void {
    if (value && !this.extensions.includes(value)) {
      this.extensions.push(value);
      this.currentInput = '';
      this.extensionsChange.emit(this.extensions);
    }
  }

  removeExtension(index: number): void {
    this.extensions.splice(index, 1);
    this.extensionsChange.emit(this.extensions);
  }
}
