
<div class="relative">

  <button [class]="class" class="flex items-center justify-start px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm dark:bg-dark-background-alt dark:border-none" (click)="toggleDropdown()">
    <ng-content select="[multi-select-icon]"></ng-content>
    <div class="flex flex-grow ml-2 dark:text-gray-400">{{ label }}</div>
    <div class="flex flex-row justify-end" *ngIf="selected.length">
      <span class="badge bg-blue-500 text-white rounded-full px-2 py-1 text-xs">{{ selected.length }}</span>
    </div>
  </button>


  <div *ngIf="showDropdown" [ngStyle]="{'width': boxSize ? boxSize + 'px' : '100%'}" class="absolute z-10 mt-1 bg-white rounded-md shadow-lg p-3 dark:bg-dark-background">
    <div class="flex flex-row relative items-center">
      <div class="absolute text-gray-500 left-4 top-1/2 transform -translate-y-1/2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6">
          <path fill-rule="evenodd" d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z" clip-rule="evenodd" />
        </svg>
      </div>
      <input type="search"
        class="rounded-lg pl-12 py-2 w-full text-base text-gray-600 bg-white border border-gray-200 focus:outline-none transition-all duration-300 ease-in-out dark:bg-dark-background-alt dark:border-none"
        [(ngModel)]="searchTerm"
        (ngModelChange)="filterOptions()"
        placeholder="Search..."
      />
      <div class="absolute right-4 inset-y-0 my-auto" *ngIf="foundCount">
       {{foundCount}}
      </div>
    </div>
    <ul class="overflow-auto mt-2">
      <li *ngFor="let option of filteredOptions; let i = index" (click)="selectOption(option)"
          [ngClass]="{
            'bg-gray-100 hover:bg-gray-200 dark:bg-dark-background-alt hover:dark:brightness-125': i % 2 === 0,
            'bg-white hover:bg-gray-100 dark:bg-dark-panel hover:dark:brightness-125': i % 2 !== 0
          }"
          class="flex items-center px-4 py-2 cursor-pointer rounded-md transition-colors duration-150 ease-in-out">
        <div class="rounded-full border-2 border-gray-300 mr-2"
             [class.bg-blue-500]="isSelected(option)"
             [class.border-blue-500]="isSelected(option)"
             style="width: 20px; height: 20px;">
          <div class="flex items-center justify-center h-full w-full">
            <div class="rounded-md bg-white w-2 h-2" *ngIf="isSelected(option)"></div>
          </div>
        </div>
        <span class="ml-2 flex-grow  dark:text-gray-300">{{ option[labelKey] }}</span>
        <ng-container *ngTemplateOutlet="extraContentTemplate; context: {$implicit: option}"></ng-container>
      </li>
    </ul>

  </div>
</div>
