import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/modules/material-design.module';
import { SplashScreenComponent } from './layout/splash-screen/splash-screen.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageModalComponent } from './shared/components/message-modal/message-modal.component';
import { PwaService } from './shared/services/pwa';

import { SurveyDatabase } from './shared/local-db/survey.database';
import { SurveysService } from './shared/services/survey.service';
import { SettingsComponent } from './features/settings/settings.component';
import { SharedModule } from './shared/modules/shared.module';
import { AppPopupComponent } from './shared/components/app-popup/app-popup.component';
import { AppPopupService } from './shared/services/app-popup.service';
import { SubscriptionHelper } from './shared/helpers/subscription.helper';

import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';

import { LayoutModule } from './layout/layout.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './shared/store/reducers/reducer';
import { AppEffects } from './shared/store/effects/app.effects';
import { environment } from 'src/environments/environment';
import { ResponseInterceptor } from './shared/interceptors/response.interceptor';

// declare var global: any;

@NgModule({
  declarations: [
    AppComponent,
    SplashScreenComponent,
    MessageModalComponent,
    SettingsComponent,
    AppPopupComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(AppEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    NgxSpinnerModule,
    LayoutModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    PwaService,
    AppPopupService,
    SurveysService,
    SurveyDatabase,
    SubscriptionHelper,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
