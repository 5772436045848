import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppPopupService } from '../services/app-popup.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  constructor(private appPopup: AppPopupService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.status === 0) {
          // window.location.href = environment.uamUrl;
          return throwError(
            () => new Error('Redirecting to login due to unauthorized request')
          );
        } else {
          this.appPopup.error('Error', err.message, 5000);
          return throwError(() => err);
        }
      })
    );
  }
}
