import Dexie from 'dexie';
import { Survey, SurveyAnswerResult } from '../entities/survey.entity';
export class SurveyDatabase extends Dexie {
  surveys!: Dexie.Table<Survey, string>;
  progress!: Dexie.Table<SurveyAnswerResult, string>;

  constructor() {
    super('SurveyDatabase');
    this.version(4).stores({
      surveys: 'eventUUID',
      progress: 'eventUUID',
    });
  }

  async clearAllSurveys(): Promise<void> {
    await this.surveys.clear();
    await this.progress.clear();
  }
  async deleteDatabase(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.delete()
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.error('Could not delete database', error);
          reject(error);
        });
    });
  }
}
