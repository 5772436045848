<div class="bottom-popup" *ngIf="showPopup" [ngClass]="'popup-' + type">
  <div class="icon">
    <mat-icon *ngIf="type === 'error'">error</mat-icon>
    <mat-icon *ngIf="type === 'warning'">warning</mat-icon>
    <mat-icon *ngIf="type === 'info'">info</mat-icon>
    <mat-icon *ngIf="type === 'success'">check_circle</mat-icon>
  </div>
  <div class="content">
    <h2>{{ title }}</h2>
    <p>{{ message }}</p>
  </div>
  <button class="dismiss-btn" (click)="dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>
