import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-badge',
  templateUrl: './error-badge.component.html',
  styleUrls: ['./error-badge.component.scss'],
})
export class ErrorBadgeComponent {
  @Input() openFrom: string = 'bottom';
  @Input() errorLabel: string = 'Error';

  @Input() warningLabel: string = 'Warnings';

  @Input() errors: string[] = [];
  @Input() warnings: string[] = [];
  showTooltip = false;
  @Input() showOnlyTotals = false;

  @Input() showIsValidMsg = false;
  @Input() validMsg: string = 'Ready to Review';

  get badgeCount(): number {
    return this.errors.length + this.warnings.length;
  }
  get errorsCount(): number {
    return this.errors.length;
  }
  get warningsCount(): number {
    return this.warnings.length;
  }

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }
}
