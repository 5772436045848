import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }

    return array.sort((a: any, b: any) => {
      const aValue = a && a[field] ? a[field] : 0;
      const bValue = b && b[field] ? b[field] : 0;
      return aValue - bValue;
    });
  }
}
