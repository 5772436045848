import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionHelper } from '../../helpers/subscription.helper';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrl: './confirmation-modal.component.scss'
})
export class ConfirmationModalComponent implements OnDestroy {

  public confirmButtonText = 'Confirm';
  public cancelButtonText = 'Cancel';
  private subsManager = new SubscriptionHelper();
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.confirmButtonText) {
      this.confirmButtonText = data.confirmButtonText;
    }
    if (data.cancelButtonText) {
      this.cancelButtonText = data.cancelButtonText;
    }
  }

  ngOnDestroy(): void {
    this.subsManager.destroy();
  }

  onConfirm(): void {
    this.dialogRef.close({ confirmed: true });
  }

  onCancel(): void {
    this.dialogRef.close({ confirmed: false });
  }
}
