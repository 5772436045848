<form [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-wrap -mx-2">
  <ng-container *ngFor="let control of config">
    <div [class]="getWidthClass(control.width)" class="px-2 mt-2 items-center">
      <div class="form-control {{control.style}}">
        <ng-container *ngTemplateOutlet="controlTemplate; context: {$implicit: control}"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #controlTemplate let-control>
    <label [attr.for]="control.name" class="block text-sm font-medium text-gray-700 dark:text-gray-400"
      [ngClass]="{'hidden': control.type === 'checkbox'}">
      {{ control.label }}
    </label>
    <input *ngIf="control.type === 'input'" [type]="control.type" [formControlName]="control.name"
      [placeholder]="control.placeholder"
      class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-header focus:border-header sm:text-sm dark:border-0 dark:bg-dark-background-alt dark:text-gray-200" />
    <select *ngIf="control.type === 'select'" [formControlName]="control.name"
      class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-header focus:border-header sm:text-sm dark:border-0 dark:bg-dark-background-alt dark:text-gray-200">
      <option *ngFor="let option of control.options" [value]="option.value">
        {{ option.label }}
      </option>
    </select>
    <app-icons-dropdown *ngIf="control.type === 'icon-select'" [formControlName]="control.name"></app-icons-dropdown>
    <div *ngIf="control.type === 'checkbox'" class="mt-1 flex items-start">
      <div class="flex items-center h-5">
        <input [id]="control.name" type="checkbox" [formControlName]="control.name"
          class="custom-checkbox focus:ring-header h-4 w-4 text-header border-gray-300 rounded" />
      </div>
      <div class="ml-3 text-sm custom-checkbox-container">
        <label [for]="control.name" class="font-medium text-gray-700 dark:text-gray-400">
          {{ control.label }}
        </label>
        <p *ngIf="control.sublabel" class="text-gray-500 dark:text-gray-400">
          {{ control.sublabel }}
        </p>
      </div>
    </div>
    <div *ngIf="control.type === 'color-picker'" class="relative flex items-start flex-row">
      <input id="{{control.name}}-color-picker" type="color" (input)="onColorPickerChange($event, control.name)"
        [value]="form.get(control.name)?.value || '#ffffff'" (paste)="onTextInputChange($event, control.name)"
        (change)="onTextInputChange($event, control.name)"
        class="absolute left-1 top-3 w-6 h-6 opacity-0 cursor-pointer">
      <input [id]="control.name" type="text" [formControlName]="control.name"
        class="mt-1 block uppercase w-full pl-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-header bg-white focus:border-header sm:text-sm dark:border-0 dark:bg-dark-background-alt dark:text-gray-200">
    </div>
    <span
      *ngIf="form.controls[control.name].errors && form.controls[control.name].touched && control.type !== 'icon-select'"
      class="text-red-500 text-xs">
      Error in {{ control.label }}
    </span>
    <span *ngIf="form.controls[control.name].errors?.['hexColor'] && control.type === 'color-picker'"
      class="text-red-500 text-xs">
      Invalid color format
    </span>
  </ng-template>
</form>