<ng-container *ngIf="progressState$ | async as progressState">
  <mat-dialog-content class="text-center">
    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100" *ngIf="progressState.totalProgress < 100">
      <img src="../../../../assets/img/upload-document.gif" alt="Uploading...">
    </div>

    <div class="mx-auto flex items-center justify-center h-[50px] w-[50px] rounded-full bg-green-400" *ngIf="progressState.totalProgress >= 100">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-[45px] w-[45px] text-white">
        <path fill-rule="evenodd" d="M16.403 12.652a3 3 0 0 0 0-5.304 3 3 0 0 0-3.75-3.751 3 3 0 0 0-5.305 0 3 3 0 0 0-3.751 3.75 3 3 0 0 0 0 5.305 3 3 0 0 0 3.75 3.751 3 3 0 0 0 5.305 0 3 3 0 0 0 3.751-3.75Zm-2.546-4.46a.75.75 0 0 0-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
      </svg>

    </div>
    <h3 class="text-md font-medium mt-3 mb-4" *ngIf="progressState.totalProgress >= 100">{{completedMessage}}</h3>

    <h3 class="text-md font-medium mt-3" *ngIf="progressState.totalProgress < 100">{{message}}</h3>
    <div class="mt-2"  *ngIf="progressState.totalProgress < 100">
      <span class="text-red-500 text-sm">Please do not close this window.</span>
    </div>
    <div class="mt-2 w-full bg-gray-200 rounded overflow-hidden">
      <div class="bg-blue-500 text-xs leading-none py-1 text-center text-white h-[25px]"
        [style.width]="currentProgress.totalProgress + '%'"
        [ngClass]="{'bg-green-500': currentProgress.totalProgress >= 100, 'bg-stripes': currentProgress.totalProgress < 100}">
      </div>
    </div>
    <div class="text-white text-sm flex flex-row justify-center w-full capitalize -mt-[22px]">
      {{ currentProgress.totalProgress >= 100 ? 'Completed' : currentProgress.completedTasks + '/' +
      currentProgress.totalTasks + ' Tasks Complete' }}
    </div>
  </mat-dialog-content>

  <!-- <mat-dialog-actions class="mt-4">
    <div class="flex flex-row w-full justify-end">
      <button class="primary-button text-sm flex flex-row gap-2" (click)="closeDialog()"
        *ngIf="progressState.totalProgress === 100">
        <span>Close ({{ countdown }}s)</span>
      </button>
    </div>
  </mat-dialog-actions> -->
</ng-container>
