
  <div mat-dialog-title class="border-b border-gray-200 h-[65px]">
<div class="flex flex-col w-full absolute " style="top:8px">
    <span class="text-sm font-normal text-gray-700">Status:</span>
    <span class="text-lg font-semibold capitalize">{{ data.title }}</span>
  </div>

</div>

<div mat-dialog-content class="mdc-dialog__content" style="display: flex; flex-wrap: wrap; justify-content: center; padding: 16px; gap: 16px;">
  <div *ngFor="let item of data.items" class="ng-star-inserted" style="width: 150px;">
    <div class="flex flex-col items-center justify-center p-2 border rounded-lg bg-white h-[112px]" style="box-sizing: border-box; width: 100%;">
      <span class="text-sm font-normal text-gray-800">{{ item.label }}</span>
      <span class="mt-1 text-[40px] font-bold text-gray-800 mt-[17px]">{{ item.count }}</span>
    </div>
  </div>
</div>
<footer mat-dialog-actions class="mdc-dialog__actions border-t border-gray-200 pt-2">
  <div class="w-full flex justify-end">
    <button class="primary-button" (click)="onClose()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
      </svg>
      close </button>
  </div>
</footer>
