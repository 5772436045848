import { CaseConverter } from '../helpers/case-converter.helper';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpHelperService } from './http-helper.service';
import { environment } from 'src/environments/environment';
import {
  Survey,
  SurveyAnswerResult,
  SurveyCodeTypes,
} from '../entities/survey.entity';
import { SurveyDatabase } from '../local-db/survey.database';
import { AppPopupService } from './app-popup.service';

@Injectable({
  providedIn: 'root',
})
export class SurveysService {
  private readonly _surveys = new BehaviorSubject<Survey[]>([]);
  readonly surveys$ = this._surveys.asObservable();
  private baseUrl = environment.endpoint;

  constructor(
    private httpHelperService: HttpHelperService,
    private db: SurveyDatabase,
    private appPopup: AppPopupService
  ) {
    this.loadInitialData();
  }

  private async processCodeResult(result: any, code: string, type: string) {
    if (result.response.validCode) {
      let survey = CaseConverter.keysToCamelCase(
        result.response.survey
      ) as Survey;

      const { event_uuid, event_name, surveyCode, send_survey_time_end } =
        result.response;
      survey.inCode = code;
      survey.eventUUID = event_uuid ?? '';
      survey.eventName = event_name ?? '';
      survey.codeType = type;
      survey.expirationTime = send_survey_time_end;
      if (type === SurveyCodeTypes.qrcode) {
        survey.accessCode = surveyCode;
      } else {
        survey.accessCode = code;
      }

      survey = await this.updateSurveyWithAssets(survey);

      const dbResult = await this.updateLocalDB(survey);
      const procResult = {
        success: dbResult.added,
        title: survey.title,
        uuid: survey.uuid,
        eventUUID: event_uuid,
      };
      return procResult;
    } else {
      return { success: false, error: result.response.codeMessage };
    }
  }
  private base64ToArrayBuffer(base64: any) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  private async updateSurveyWithAssets(survey: Survey) {
    let mainImageKey: string | null = null;

    if (survey.image && typeof survey.image === 'string') {
      mainImageKey = survey.image;
      const mainAssetBase64 = await this.getSurveyAssets([survey.image]);
      if (mainAssetBase64 && mainAssetBase64[survey.image]) {
        const mainArrayBuffer = this.base64ToArrayBuffer(
          mainAssetBase64[survey.image]
        );

        survey.assets = { ...survey.assets, [mainImageKey]: mainArrayBuffer };
      }
    }

    const allImageKeys = survey.questions.flatMap((question) =>
      question.metadata && question.metadata.assets
        ? Object.keys(question.metadata.assets).map(
            (key) => question.metadata.assets[key].imageKey
          )
        : []
    );

    const assetPromises = allImageKeys.map((imageKey) =>
      this.getSurveyAssets([imageKey]).then((result) => ({
        [imageKey]: result[imageKey],
      }))
    );

    const assetsResults = await Promise.all(assetPromises);

    const assetsArrayBuffers = assetsResults.reduce((acc, result) => {
      const key = Object.keys(result)[0] as any;
      const base64 = result[key];
      if (base64) {
        acc[key] = this.base64ToArrayBuffer(base64);
      }
      return acc;
    }, survey.assets || {});

    survey.assets = assetsArrayBuffers;

    if (mainImageKey) {
      survey.image = mainImageKey;
    }

    return survey;
  }

  addSurveyByCodePromise(code: string, type: string): Promise<any> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const localResult = await this.getSurveyByCodeFromLocalDB(code, type);
        if (!localResult.found) {
          const result: any = await this.httpHelperService.requestPromise(
            'POST',
            `${this.baseUrl}/check-code`,
            { code, type: type.toUpperCase() }
          );
          if (result && result.response) {
            if (result.response.success) {
              resolve(await this.processCodeResult(result, code, type));
            } else {
              resolve(result.response);
            }
          }
        } else if (localResult.found) {
          resolve(localResult);
        } else {
          console.error(localResult.error);
          reject({ success: false });
        }
      } catch (err) {
        reject({ success: false, error: err });
      }
    });
  }
  async checkCodeStatus(codes: string[]): Promise<any> {
    try {
      const result = await this.httpHelperService.requestPromise(
        'POST',
        `${this.baseUrl}/check-multi-code`,
        { codes }
      );

      const response = CaseConverter.keysToCamelCase(result);
      return { success: true, codes: response };
    } catch (error) {
      return { success: false, error };
    }
  }
  async getSurveyAssets(keys: string[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpHelperService
        .requestPromise('POST', `${this.baseUrl}/get-survey-assets`, { keys })
        .then((data) => {
          if (data.response) {
            resolve(data.response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  async submit(results: SurveyAnswerResult): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.httpHelperService
        .requestPromise(
          'POST',
          `${this.baseUrl}/submit-results`,
          CaseConverter.keysToSnakeCase(results)
        )
        .then((response) => {
          if (response.submitted) {
            // Update local DB to mark the survey as submitted
            this.db.surveys
              .update(results.eventUUID, { submitted: true })
              .then(() => {
                resolve(response);
              })
              .catch((err: any) => {
                console.error(`Failed to update survey in local DB: ${err}`);
              });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getSurveys(): Observable<Survey[]> {
    if (navigator.onLine) {
      return this.getSurveysFromLocalDB();
    } else {
      return of([]);
    }
  }

  getSurveyById(id: string): Observable<Survey> {
    return new Observable<Survey>((subscriber) => {
      this.db.surveys
        .get(id)
        .then((survey: any) => {
          if (survey) {
            subscriber.next(survey);
            subscriber.complete();
          } else {
            console.error(`Survey with id ${id} not found`);
            subscriber.error(`Survey with id ${id} not found`);
          }
        })
        .catch((error: any) => {
          console.error('An error occurred:', error);
          subscriber.error(`An error occurred: ${error.message}`);
        });
    });
  }

  deleteAll() {
    return this.db.deleteDatabase();
  }
  async deleteProgress(eventUUID?: string) {
    try {
      if (eventUUID) {
        await this.db.progress.delete(eventUUID);
      }
    } catch (error) {
      console.error('Error deleting progress:', error);
    }
  }
  async deleteSurvey(eventUUID?: string) {
    try {
      if (eventUUID) {
        await this.db.surveys.delete(eventUUID); //survey data also deleted from local
      }
    } catch (error) {
      console.error('Error deleting progress:', error);
    }
  }

  private loadInitialData() {
    this.db.surveys.toArray().then((surveys: any) => {
      this._surveys.next(surveys);
    });
  }

  private updateLocalDB(survey: Survey): Promise<any> {
    return this.db.surveys
      .add(survey)
      .then(() => {
        this.loadInitialData();
        return { added: true };
      })
      .catch((error: any) => {
        if (error.name === 'ConstraintError') {
          this.appPopup.warning(
            'Warning',
            `Survey ${survey.title} already exists`
          );
        } else {
          console.error('Error updating single survey in local DB:', error);
        }
        return { added: false };
      });
  }

  private getSurveysFromLocalDB(): Observable<Survey[]> {
    return from(this.db.surveys.toArray()).pipe(
      map((surveys) =>
        surveys.sort((a: any, b: any) => {
          const eventNameComparison = a.eventName
            .toLowerCase()
            .localeCompare(b.eventName.toLowerCase());
          return (
            eventNameComparison ||
            a.title.toLowerCase().localeCompare(b.title.toLowerCase())
          );
        })
      ),
      tap((sortedSurveys) => this._surveys.next(sortedSurveys))
    );
  }
  private async getSurveyByCodeFromLocalDB(
    code: string,
    codeType: string
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const allSurveys = await this.db.surveys.toArray();
        const survey = allSurveys.find(
          (s: any) => s.inCode === code && s.codeType === codeType
        );

        if (survey) {
          resolve({
            found: true,
            success: true,
            title: survey.title,
            uuid: survey.uuid,
            eventUUID: survey.eventUUID,
          });
        } else {
          resolve({ found: false, success: true });
        }
      } catch (error) {
        reject({ success: false, error });
      }
    });
  }

  getSavedInProgressSurvey(eventUUID: string): Promise<SurveyAnswerResult> {
    return new Promise<SurveyAnswerResult>(async (resolve, reject) => {
      try {
        const progress = await this.db.progress.get(eventUUID);
        resolve(progress || ({} as SurveyAnswerResult));
      } catch (error) {
        console.error('Error fetching record:', error);
        reject(error);
      }
    });
  }
}
