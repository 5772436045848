import { trigger, transition, style, animate } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('* <=> *', [
    style({ position: 'absolute', opacity: 0 }),
    animate('300ms ease-out', style({ opacity: 1 })),
  ]),
]);
// import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('main-list <=> detail', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 })
//       ], { optional: true }),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ], { optional: true }),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true })
//     ]),
//     transition('main-list <=> sub-list', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [ style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 }) ], { optional: true }),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ], { optional: true }),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true })
//     ]),
//     transition('detail <=> sub-list', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [ style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 }) ], { optional: true }),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ], { optional: true }),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true })
//     ])
//   ]);

// import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('main-list <=> detail', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 })
//       ]),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ]),
//       query(':leave', animateChild()),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ]),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ])
//       ]),
//       query(':enter', animateChild())
//     ]),
//     transition('main-list <=> sub-list', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [ style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 }) ]),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ]),
//       query(':leave', animateChild()),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ]),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ])
//       ]),
//       query(':enter', animateChild())
//     ]),
//     transition('detail <=> sub-list', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [ style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 }) ]),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ]),
//       query(':leave', animateChild()),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ]),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ])
//       ]),
//       query(':enter', animateChild())
//     ])
//   ]);

// import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('main-list <=> detail', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 })
//       ], { optional: true }),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ], { optional: true }),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true })
//     ]),
//     transition('main-list <=> sub-list', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 })
//       ], { optional: true }),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ], { optional: true }),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true })
//     ]),
//     transition('detail <=> main-list', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({ position: 'absolute', top: 0, left: 0, width: '100%', opacity: 1 })
//       ], { optional: true }),
//       query(':enter', [ style({ left: '100%', opacity: 0 }) ], { optional: true }),
//       query(':leave', animateChild(), { optional: true }),
//       group([
//         query(':leave', [ animate('300ms ease-out', style({ left: '-100%', opacity: 0 })) ], { optional: true }),
//         query(':enter', [ animate('300ms ease-out', style({ left: '0%', opacity: 1 })) ], { optional: true })
//       ]),
//       query(':enter', animateChild(), { optional: true })
//     ])
//   ]);
// import { trigger, transition, style, query, animateChild, group, animate } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('main-list => sub-list', sharedTransition('400ms ease-in-out')),
//     transition('sub-list => main-list', sharedTransition('400ms ease-in-out')),
//     transition('sub-list => detail', sharedTransition('400ms ease-in-out')),
//     transition('detail => sub-list', sharedTransition('400ms ease-in-out')),
//     transition('detail => main-list', sharedTransition('400ms ease-in-out')),
//   ]);

// function sharedTransition(timeAndEase: string) {
//   return [
//     style({ position: 'relative' }),
//     query(':enter, :leave', [
//       style({
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//       }),
//     ], { optional: true }),
//     query(':enter', [style({ left: '100%' })], { optional: true }),
//     query(':leave', animateChild(), { optional: true }),
//     group([
//       query(':leave', [animate(timeAndEase, style({ left: '-100%' }))], { optional: true }),
//       query(':enter', [animate(timeAndEase, style({ left: '0%' }))], { optional: true }),
//     ]),
//     query(':enter', animateChild(), { optional: true }),
//   ];
// }

// import { trigger, transition, style, query, animate, group } from '@angular/animations';

// export const slideInAnimation =
//   trigger('routeAnimations', [
//     transition('* <=> *', [
//       style({ position: 'relative' }),
//       query(':enter, :leave', [
//         style({
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%'
//         })
//       ], { optional: true }),
//       query(':enter', [
//         style({ left: '100%' })
//       ], { optional: true }),
//       group([
//         query(':leave', [
//           animate('9000ms ease-out', style({ left: '-100%' }))
//         ], { optional: true }),
//         query(':enter', [
//           animate('9000ms ease-out', style({ left: '0%' }))
//         ], { optional: true })
//       ])
//     ])
//   ]);

// import { trigger, transition, style, animate } from '@angular/animations';

// export const slideInAnimation = trigger('slideInAnimation', [
//   transition(':enter', [
//     style({ transform: 'translateX(-100%)' }),
//     animate('0.5s ease-in', style({ transform: 'translateX(0%)' })),
//   ]),
//   transition(':leave', [
//     animate('0.5s ease-out', style({ transform: 'translateX(100%)' })),
//   ]),
// ]);
