import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppUserState } from '../store/states/app.state';

interface AppState {
  selectedProject: string | null;
  currentUser: AppUserState | null;
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private initialState: AppState = {
    selectedProject: null,
    currentUser: null,
  };

  private appStateSubject = new BehaviorSubject<AppState>(this.initialState);
  appState$: Observable<AppState> = this.appStateSubject.asObservable();

  constructor() { }

  updateSelectedProject(selectedProject: string | null): void {
    const currentState = this.appStateSubject.getValue();
    const newState = {
      ...currentState,
      selectedProject,
    };
    this.appStateSubject.next(newState);
  }
}
