
<div class="rounded-lg cursor-pointer overflow-hidden {{widthClass}} {{heightClass}}"
     [ngClass]="{'p-4 border-2 border-dashed border-gray-300': !imageUrl}"
     (click)="fileInput.click()"
     (drop)="onFileChanged($event)"
     [class.disabled]="readonly"
     (dragover)="$event.preventDefault()">
  <input [id]="inputId" type="file" #fileInput (change)="onFileChanged($event)" class="hidden" accept="image/png, image/jpeg">
  <ng-container *ngIf="imageUrl; else uploadIcon">
    <img [src]="imageUrl" class="object-cover rounded-lg h-full w-full" alt="Image preview">
  </ng-container>
  <ng-template #uploadIcon>
    <div class="flex flex-col items-center justify-center h-full w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
        <path d="M9.25 13.25a.75.75 0 0 0 1.5 0V4.636l2.955 3.129a.75.75 0 0 0 1.09-1.03l-4.25-4.5a.75.75 0 0 0-1.09 0l-4.25 4.5a.75.75 0 1 0 1.09 1.03L9.25 4.636v8.614Z" />
        <path d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z" />
      </svg>
    </div>
  </ng-template>
</div>
