import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Tag } from '../../entities/app.entity';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss',
})
export class TagComponent {
  @Input() tag!: Tag;
  @Output() remove = new EventEmitter<void>();

  get textColor() {
    return this.getContrastYIQ(this.tag.color);
  }

  getContrastYIQ(hexcolor: string): string {
    hexcolor = hexcolor.replace('#', '');

    const r = parseInt(hexcolor.slice(0, 2), 16);
    const g = parseInt(hexcolor.slice(2, 4), 16);
    const b = parseInt(hexcolor.slice(4), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }
}
