import { createReducer, on } from '@ngrx/store';
import * as UserActions from '../actions/user.actions';
import { AppUserState } from '../states/app.state';

export const initialCurrentUserState: AppUserState = {
  sub: '',
  name: '',
  role: '',
  roleName: '',
  organization: '',
  projects: [],
  email: '',
  phone_number: '',
  family_name: '',
};

export const currentUserReducer = createReducer(
  initialCurrentUserState,
  on(UserActions.setCurrentUser, (state, { currentUser }) => currentUser),
  on(UserActions.clearCurrentUser, () => initialCurrentUserState),
);
