import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longDateTime',
})
export class LongDateTimePipe implements PipeTransform {
  transform(value: any): any {
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    } else {
      const optionsDate: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      const optionsTime: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      };

      const dateString = date.toLocaleDateString(undefined, optionsDate);
      const timeString = date.toLocaleTimeString(undefined, optionsTime);

      return `${dateString} - ${timeString}`;
    }
  }
}
