import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  ViewContainerRef,
  ComponentRef,
  OnInit,
} from '@angular/core';
import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TooltipDirective implements OnInit {
  @Input('appTooltip') tooltipText: string | string[] = '';
  private tooltipComponentRef?: ComponentRef<TooltipComponent>;

  constructor(
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit(): void { }

  @HostListener('mouseenter') onMouseEnter() {
    this.showTooltip();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hideTooltip();
  }

  private showTooltip() {
    if (!this.tooltipComponentRef) {
      this.tooltipComponentRef =
        this.viewContainerRef.createComponent(TooltipComponent);
      this.tooltipComponentRef.instance.text = this.formatTooltipText(
        this.tooltipText
      );
      const rect = this.elementRef.nativeElement.getBoundingClientRect();
      this.tooltipComponentRef.instance.position = {
        top: `${rect.bottom + 5}px`,
        left: `${rect.left}px`,
      };
      this.tooltipComponentRef.changeDetectorRef.detectChanges();
    }
  }

  private hideTooltip() {
    if (this.tooltipComponentRef) {
      this.tooltipComponentRef.destroy();
      this.tooltipComponentRef = undefined;
    }
  }

  private formatTooltipText(text: string | string[]): string {
    if (Array.isArray(text)) {
      return `<ul>${text.map(item => `<li>${item}</li>`).join('')}</ul>`;
    }
    return text;
  }
}
