import { Component } from '@angular/core';
import { ThemeService } from '../../shared/services/theme.service';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'app-theme-toggle',
  templateUrl: './theme-toggle.component.html',
  styleUrl: './theme-toggle.component.scss',
  animations: [
    trigger('slideToggle', [
      state(
        'light',
        style({
          transform: 'translateX(0%)',
        })
      ),
      state(
        'dark',
        style({
          transform: 'translateX(100%)',
        })
      ),
      transition('light <=> dark', animate('300ms ease-in-out')),
    ]),
  ],
})
export class ThemeToggleComponent {
  isDarkTheme = false;

  constructor(private themeService: ThemeService) { }

  ngOnInit() {
    this.isDarkTheme = this.themeService.isDarkTheme();
  }

  toggleTheme() {
    this.themeService.toggleTheme();
    this.isDarkTheme = this.themeService.isDarkTheme();
  }
}
