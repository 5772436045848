import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss'],
})
export class TagInputComponent implements OnChanges, OnInit {
  @Input() availableTags: string[] = [];
  selectedTags: string[] = [];
  tagControl = new FormControl();
  filteredTags: Observable<string[]>;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() tags: string[] = [];
  @Input() canEdit: boolean = true;

  @Output() tagsChange = new EventEmitter<string[]>();
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  constructor(private cdr: ChangeDetectorRef) {
    this.filteredTags = this.tagControl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.availableTags.slice()
      )
    );
  }

  ngOnInit() {
    if (!this.canEdit) {
      this.tagControl.disable();
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.availableTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tags']) {
      this.selectedTags = this.tags;
    }

    if (changes['canEdit']) {
      if (this.canEdit) {
        this.tagControl.enable();
      } else {
        this.tagControl.disable();
      }
      this.cdr.detectChanges();
    }
  }

  add(event: any): void {
    const value = (event.value || '').trim();

    if (value && !this.selectedTags.some((tag) => tag === value)) {
      this.selectedTags.push(value);
    }

    if (event.input) {
      event.input.value = '';
    }

    this.tagControl.setValue(null);
    this.tagsChange.emit(this.selectedTags);
  }

  edit(tag: string, event: any): void {
    const value = (event.value || '').trim();

    if (value) {
      const index = this.selectedTags.indexOf(tag);
      if (index > -1) {
        this.selectedTags[index] = value;
      }
    }
  }

  onSelection(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value.trim();
    if (value && !this.selectedTags.some((tag) => tag === value)) {
      this.selectedTags.push(value);
    }
    this.tagControl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
    this.tagsChange.emit(this.selectedTags);
  }
}
