<div class="grid grid-cols-1 md:grid-cols-1 gap-4">
  <div *ngFor="let item of [].constructor(20); let i = index" class="card bg-white dark:bg-dark-panel shadow rounded-lg p-4 flex flex-col space-y-2">
    <div class="animate-pulse bg-gray-300 h-4 w-full rounded"></div>
    <div class="animate-pulse bg-gray-300 h-4 w-3/4 rounded"></div>
    <div class="animate-pulse bg-gray-300 h-4 w-5/6 rounded"></div>
    <div class="animate-pulse bg-gray-300 h-4 w-4/5 rounded"></div>
    <div class="animate-pulse bg-gray-300 h-4 w-2/3 rounded"></div>
    <div class="animate-pulse bg-gray-300 h-4 w-1/2 rounded"></div>
  </div>
</div>
