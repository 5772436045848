import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpEventType,
} from '@angular/common/http';
import { Observable, lastValueFrom, filter, map } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {
  constructor(private http: HttpClient) {}

  request(
    method: string,
    url: string,
    body?: any,
    params?: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: { params?: any; headers?: HttpHeaders } = { headers };
    if (params) {
      options['params'] = params;
    }

    return this.http
      .request(method, url, { body, ...options })
      .pipe(catchError(this.handleError));
  }

  async requestPromise(
    method: string,
    url: string,
    body?: any,
    params?: any
  ): Promise<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options: { params?: any; headers?: HttpHeaders } = { headers };
    if (params) {
      options['params'] = params;
    }

    const observable$ = this.http
      .request(method, url, { body, ...options })
      .pipe(catchError(this.handleError));

    try {
      return await lastValueFrom(observable$);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.handleError(error);
      } else {
        console.error('An unknown error occurred:', error);
      }
      throw error;
    }
  }

  handleError(error: HttpErrorResponse) {
    return new Observable((subscriber) => {
      subscriber.error(error);
    });
  }

  async requestWithProgressPromise(
    method: string,
    url: string,
    onProgress: (progress: number) => void,
    body?: any,
    params?: any
  ): Promise<any> {
    const options: {
      params?: any;
      reportProgress: boolean;
      observe: 'events';
    } = {
      reportProgress: true,
      observe: 'events',
    };

    if (params) {
      options['params'] = params;
    }

    const observable$ = this.http
      .request(method, url, { body, ...options })
      .pipe(
        catchError(this.handleError),
        tap((event: any) => {
          if (
            event.type === HttpEventType.UploadProgress ||
            event.type === HttpEventType.DownloadProgress
          ) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            onProgress(percentDone);
          }
        }),
        filter((event) => event.type === HttpEventType.Response),
        map((event) => event.body)
      );

    try {
      return await lastValueFrom(observable$);
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this.handleError(error);
      } else {
        console.error('An unknown error occurred:', error);
      }
      throw error;
    }
  }
}
