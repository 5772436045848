export class CaseConverter {
  static toCamelCase(str: string): string {
    return str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
  }

  static toSnakeCase(str: string): string {
    return str
      .replace(/([A-Z]+)([A-Z][a-z])/g, '_$1_$2')
      .replace(/([a-z\d])([A-Z])/g, '$1_$2')
      .toLowerCase()
      .replace(/^_/, '');
  }
  static keysToCamelCase(obj: any, exclude: string[] = []): any {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(v => this.keysToCamelCase(v, exclude));
    } else if (typeof obj === 'object') {
      return Object.keys(obj).reduce<{ [key: string]: any; }>((result, key) => {
        const camelKey = this.toCamelCase(key);
        const modifiedKey = this.replaceUuidWithUUID(camelKey);

        if (exclude.includes(key)) {
          result[key] = obj[key];
        } else {

          result[modifiedKey] = this.keysToCamelCase(obj[key], exclude);
        }
        return result;
      }, {});
    }

    return obj;
  }
  static replaceUuidWithUUID(key: string): string {
    return key.replace(/Uuid/g, 'UUID');
  }

  static keysToSnakeCase(obj: any): any {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((v) => this.keysToSnakeCase(v));
    } else if (typeof obj === 'object') {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [this.toSnakeCase(key)]: this.keysToSnakeCase(obj[key]),
        }),
        {}
      );
    }

    return obj;
  }
}
