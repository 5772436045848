<div
  class="app-splash-screen"
  [ngStyle]="{ left: windowWidth }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    <!-- <div class="app-label">TOUCH POINT</div> -->
    <div class="app-loader">
      <div class="container-body">
        <div class="row">
          <div id="loader">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
