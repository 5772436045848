import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent {
  @Input() isSlimMode: boolean = true;
  @Input() hideSubSideBar: boolean = true;
  @Input() hideSideBar: boolean = true;
  constructor() { }
  ngOnInit(): void { }
}
