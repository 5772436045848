<div class="bg-red-500 w-[325px]">
  <span class="text-white font-bold px-4 text-xl">Oops! Something went wrong.</span>
  <div class="flex items-between text-red-100 text-white text-sm font-bold px-4 pb-4 mt-2" role="alert">
    <span>{{ message }}</span>

  </div>
  <button
    class="ml-4 bg-red-700 hover:bg-red-600 text-white text-xs py-1 px-2 rounded focus:outline-none focus:shadow-outline flex flex-row"
    (click)="toggleDetail()">
    @if(!showDetail){
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 5.25 7.5 7.5 7.5-7.5m-15 6 7.5 7.5 7.5-7.5" />
    </svg>
    } @else {
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
      class="w-4 h-4">
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 18.75 7.5-7.5 7.5 7.5" />
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 7.5-7.5 7.5 7.5" />
    </svg>

    }


    <span class="ml-2">More Detail</span>

  </button>

  <div *ngIf="showDetail" class="mx-1 p-2 text-gray-800 text-xs rounded">
    <pre class="whitespace-pre-wrap m-0 p-0">{{ details }}</pre>
  </div>
</div>
