<div class="relative">
  <button (click)="toggleDropdown()" [class]="class" class="px-1 py-1 border border-gray-300 bg-gray-50 dark:bg-gray-800 dark:border-none dark:text-gray-400 text-gray-600 rounded-md focus:outline-none flex items-center justify-between hover:text-gray-900">
    <div class="capitalize pl-2"> <span class="mr-2" *ngIf="labelInSelect">{{label}}:</span> <span [ngClass]="{'text-gray-800 dark:text-gray-300 font-semibold':labelInSelect}">{{ replace(getDisplayText()) }}</span></div>
    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
    </svg>
  </button>

  <div *ngIf="dropdownOpen" class="absolute mt-1 w-full rounded-md bg-white shadow-lg z-10 dark:bg-gray-800">
    <ul class="divide-y divide-gray-200 dark:divide-gray-300 dark:text-gray-400 text-gray-700">
      <li *ngFor="let option of options" (click)="selectOption(option)"
          class="flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300 cursor-pointer"
          [class.bg-gray-200]="isOptionSelected(option)"
          [class.dark:bg-gray-600]="isOptionSelected(option)"
          >
        <svg *ngIf="isOptionSelected(option)" class="w-4 h-4 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
        </svg>
        <span class="capitalize">{{ replace(getOptionLabel(option)) }}</span>
      </li>
    </ul>
  </div>
</div>
