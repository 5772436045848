<!-- <div class="flex items-center space-x-4 mt-2">
  <div class="flex items-center">
    <input type="radio" id="yes-option-{{field.fieldId}}" value="yes" [attr.name]="'field-' + field.fieldId"
      (change)="handleValueChange('yes')" [checked]="field.value === 'yes'"
      class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
    <label for="yes-option-{{field.fieldId}}"
      class="ml-2 block text-sm leading-5 font-medium text-gray-700 cursor-pointer">
      Yes
    </label>
  </div>
  <div class="flex items-center">
    <input type="radio" id="no-option-{{field.fieldId}}" value="no" [attr.name]="'field-' + field.fieldId"
      (change)="handleValueChange('no')" [checked]="field.value === 'no'"
      class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
    <label for="no-option-{{field.fieldId}}"
      class="ml-2 block text-sm leading-5 font-medium text-gray-700 cursor-pointer">
      No
    </label>
  </div>
</div> -->

<div class="flex items-center space-x-4 mt-2">
  <div class="flex items-center">
    <input type="radio" id="yes-option-{{field.fieldId}}" value="yes" [attr.name]="'field-' + field.fieldId"
      (change)="handleValueChange('yes')" [checked]="field.value === 'yes'" [disabled]="disabled"
      class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
    <label for="yes-option-{{field.fieldId}}"
      class="ml-2 block text-sm leading-5 font-medium text-gray-700 cursor-pointer">
      Yes
    </label>
  </div>
  <div class="flex items-center">
    <input type="radio" id="no-option-{{field.fieldId}}" value="no" [attr.name]="'field-' + field.fieldId"
      (change)="handleValueChange('no')" [checked]="field.value === 'no'" [disabled]="disabled"
      class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out" />
    <label for="no-option-{{field.fieldId}}"
      class="ml-2 block text-sm leading-5 font-medium text-gray-700 cursor-pointer">
      No
    </label>
  </div>
</div>
