import { ThemeService } from './../../shared/services/theme.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
// import { AuthService } from 'src/app/shared/services/auth.service';
import { AppUserService } from 'src/app/shared/services/app-user.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState, AppUserState } from 'src/app/shared/store/states/app.state';
import { Store } from '@ngrx/store';
import { SubscriptionHelper } from 'src/app/shared/helpers/subscription.helper';
import { selectCurrentUser } from 'src/app/shared/store/selectors/user.selector';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title1 = 'OSD WARFIGHTERS';
  title2 = 'TOUCHPOINT';
  isDevEnvironment = false;
  unreadNotifications = 6;
  notificationIcon = 'notifications_none';
  isAdminRoute = false;
  isAuthRoute = false;
  isRegistrationRoute = false;
  currentUser!: AppUserState;
  organization = 'PavCon';
  projects: any[] = [];
  selectedProject: string = '';
  userMenuOpen = false;
  isPreviewRoute = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private platform: Platform,
    public dialog: MatDialog,
    private appUser: AppUserService,
    private appState: AppStateService,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    private subMgr: SubscriptionHelper

  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        const url = event.urlAfterRedirects;
        this.isAdminRoute = url.includes('/admin');
        this.isAuthRoute = url.includes('/auth');
        this.isPreviewRoute = url.includes('previewId=');
        this.isRegistrationRoute = url.includes('/registration');
        // if (this.isAdminRoute) {
        //   await this.loadUserDetails();
        // }
      });
  }
  ngOnInit(): void {
    this.subMgr.add(
      this.store
        .select(selectCurrentUser)
        .subscribe((currentUser: AppUserState) => {
          if (currentUser.sub !== '') {
            this.currentUser = currentUser;
            this.loadProjects(this.currentUser.organization);
          }
        })
    );
  }
  checkUserPermissions() {

  }

  // async loadUserDetails() {
  //   const user = this.appUser.getAuthenticatedUser() as any;
  //   console.log('user from load', user);
  //   if (user && user.sub) {
  //     await this.appUser
  //       .getUserDetails()
  //       .then((userDetails) => {
  //         console.log('user details', userDetails);
  //         this.currentUser = {
  //           ...user,
  //           role: user['custom:warfighter_role'],
  //           ...userDetails,
  //         } as AppUserState;
  //         this.store.dispatch(
  //           setCurrentUser({ currentUser: this.currentUser }));
  //         this.themeService.activateLightMode();
  //         this.organization = userDetails.organization.toUpperCase();

  //         this.loadProjects(this.organization);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }

  // }

  loadProjects(organization: string) {
    this.appUser
      .getOrganizationProjects(organization)
      .then((projects: any[]) => {
        if (projects) {
          this.projects = projects.map((p) => p.project);
          if (this.projects.length > 0) {
            if (!this.selectedProject.length) {
              this.selectedProject = this.projects[0] as string;
              this.appState.updateSelectedProject(this.selectedProject);
            }
            this.cdr.detectChanges();
          }
        }
      })
      .catch((error) => {
        console.log('error on load projects', error);
      });
  }

  onProjectChanged(project: any) {
    this.selectedProject = project;
    if (this.selectedProject) {
      this.appState.updateSelectedProject(this.selectedProject);
    }
  }

  goHome() {
    if (this.isAdminRoute) {
      this.router.navigateByUrl('/admin');
    } else {
      this.router.navigateByUrl('/');
    }
  }
  get safeAreaHeight() {
    if (!this.platform.isBrowser) {
      return 0;
    }
    alert('is not browser');
    const height = window.innerHeight * window.devicePixelRatio;
    const safeAreaHeight = height - 44;
    return safeAreaHeight;
  }
  openNotifications() {
    this.notificationIcon =
      this.unreadNotifications > 0 ? 'notifications' : 'notifications_none';
    this.router.navigateByUrl('/activity');
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    // this.authService.signOut();
  }

  selectProject(projectName: string) {
    this.selectedProject = projectName;
  }
  applyProjectSelection() {
    this.appUser.setSelectedProject(this.selectedProject);
  }
  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }
}
