<div class="tags-container">
  <mat-form-field class="chip-list">
    <mat-label>Tags</mat-label>
    <mat-chip-grid #chipGrid aria-label="Enter tags">
      <mat-chip-row
        *ngFor="let tag of selectedTags"
        (removed)="remove(tag)"
        [editable]="true"
        (edited)="edit(tag, $event)"
        [aria-description]="'press enter to edit ' + tag"
      >
        {{ tag }}
        <button
          matChipRemove
          [attr.aria-label]="'remove ' + tag"
          *ngIf="canEdit"
        >
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="{{ canEdit ? 'New tag...' : '' }}"
        [matChipInputFor]="chipGrid"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
        [matAutocomplete]="auto"
        [formControl]="tagControl"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onSelection($event)"
    >
      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
        {{ tag }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
