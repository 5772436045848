import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch-v2',
  templateUrl: './toggle-switch-v2.component.html',
  styleUrls: ['./toggle-switch-v2.component.scss']
})
export class ToggleSwitchV2Component {
  @Input() isChecked: boolean = false;
  @Output() onChange = new EventEmitter<boolean>();

  toggle() {
    this.isChecked = !this.isChecked;
    this.onChange.emit(this.isChecked);
  }

}
