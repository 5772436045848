<div *ngIf="field.metadata.textDataType; else noInputType">
  <div [ngSwitch]="field.metadata.textDataType">

    <textarea *ngSwitchCase="'multi-lines'" [id]="field.fieldId" [attr.rows]="field.metadata.textLinesLimit || 1"
      [maxLength]="field.metadata.textLimit || 50" (input)="handleTextareaChange($event)" [value]="field.value || ''"
      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md resize-none">
    </textarea>



    <div *ngSwitchCase="'phone-number'" class="relative flex items-center w-full">
      <lucide-icon [name]="getIconName(field.metadata.textDataType)"
        class="absolute inset-y-0 w-[30px] h-[30px] left-0 pl-3 mr-2 mt-[4px] text-gray-400">
      </lucide-icon>
      <input [mask]="getMask(field.metadata.textDataType)" [(ngModel)]="field.value"
        (ngModelChange)="onPhoneChange($event)" type="text"
        class="flex-1 mt-1 block w-full pl-10 pr-10 py-2 text-base border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
    </div>

    <div *ngSwitchDefault class="relative flex items-center w-full">
      <lucide-icon [name]="getIconName(field.metadata.textDataType)"
        *ngIf="['email', 'url'].includes(field.metadata.textDataType)"
        class="absolute inset-y-0 w-[30px] h-[30px] left-0 pl-3 mr-2 mt-[4px] text-gray-400">
      </lucide-icon>
      <input [type]="field.metadata.textDataType === 'numeric' ? 'number' : 'text'" [id]="field.fieldId"
        [max]="field.metadata.textLimit || 50" [value]="field.value || ''" (input)="handleTextareaChange($event)" class="flex-1 mt-1 block w-full pl-10 pr-10 py-2 text-base border border-gray-300 bg-white focus:outline-none
      focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        [style.paddingLeft]="['email', 'url'].includes(field.metadata.textDataType) ? '2rem' : '0.75rem'">
    </div>
  </div>
  <span class="text-xs text-gray-500"
    *ngIf="!['email', 'phone-number', 'url', 'numeric'].includes(field.metadata.textDataType)">
    {{field.metadata.textLimit || 50}} Characters Limit</span>
  <div *ngIf="!isValid" class="text-red-500 text-sm">{{ errorMessage }}</div>
</div>
<ng-template #noInputType>
  <p class="text-xs text-gray-500">No input type specified.</p>
</ng-template>
