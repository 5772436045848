import { Component, Inject, Optional } from '@angular/core';
import { HotToastRef } from '@ngxpert/hot-toast';

@Component({
  selector: 'app-toast-error-detail',
  templateUrl: './toast-error-detail.component.html',
  styleUrl: './toast-error-detail.component.scss',
})
export class ToastErrorDetailComponent {
  message: string = '';
  details: string = '';
  showDetail: boolean = false;

  constructor(
    @Optional()
    @Inject(HotToastRef)
    public toastRef: HotToastRef<{ message: string; details: string }>
  ) {
    this.message = toastRef.data.message;
    this.details = toastRef.data.details;
  }

  toggleDetail() {
    this.showDetail = !this.showDetail;
  }
}
