import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { SubscriptionHelper } from '../../helpers/subscription.helper';


@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config!: any[];
  @Input() initialValues: any;
  form!: FormGroup;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();
  private subsManager = new SubscriptionHelper();

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.form = this.createGroup(this.config);
    this.applyInitialValues();
    this.subscribeToChanges();
    this.subscribeToColorPickerChanges();
  }

  applyInitialValues() {
    if (this.initialValues) {
      this.form.patchValue(this.initialValues);
      this.updateColorPickers();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['config'] && changes['config'].currentValue) {
      this.form = this.createGroup(this.config);
      this.applyInitialValues();
      this.subscribeToChanges();
      this.subscribeToColorPickerChanges();
    }

    if (changes['initialValues'] && !changes['initialValues'].isFirstChange()) {
      this.form.patchValue(this.initialValues || {});
      this.updateColorPickers(); // Llamar después de aplicar los valores iniciales
    }
  }

  ngOnDestroy(): void {
    this.subsManager.destroy();
  }

  createGroup(config: any[]): FormGroup {
    const group = new FormGroup({});
    config.forEach((control) => {
      group.addControl(
        control.name,
        new FormControl(
          control.type === 'checkbox' ? false : '',
          this.mapValidators(control.validators, control.type)
        )
      );
    });
    return group;
  }

  subscribeToChanges() {
    this.subsManager.add(
      this.form.valueChanges.subscribe((values) => {
        this.formChanges.emit(values);
      })
    );
  }

  subscribeToColorPickerChanges() {
    this.config.forEach((control) => {
      if (control.type === 'color-picker') {
        const controlFormControl = this.form.get(control.name);
        controlFormControl?.valueChanges.subscribe((value) => {
          const colorPicker = document.getElementById(
            `${control.name}-color-picker`
          ) as HTMLInputElement;
          if (colorPicker && this.isValidHex(value)) {
            colorPicker.value = value;
          }
        });
      }
    });
  }

  updateColorPickers() {
    this.config.forEach((control) => {
      if (control.type === 'color-picker') {
        const value = this.form.get(control.name)?.value;
        const colorPicker = document.getElementById(
          `${control.name}-color-picker`
        ) as HTMLInputElement;
        if (colorPicker && this.isValidHex(value)) {
          colorPicker.value = value;
        }
      }
    });
  }

  mapValidators(validators: any, controlType: string): ValidatorFn[] {
    const formValidators = [];
    if (validators.required) {
      formValidators.push(Validators.required);
    }
    if (validators.min) {
      formValidators.push(Validators.min(validators.min));
    }
    if (controlType === 'color-picker') {
      formValidators.push(this.hexColorValidator());
    }
    return formValidators;
  }

  onSubmit() {
    if (this.form.valid) {
      this.formSubmitted.emit(this.form.value);
    } else {
      this.form.markAllAsTouched();
    }
  }

  getWidthClass(width: string): string {
    switch (width) {
      case 'full':
        return 'w-full';
      case 'half':
        return 'w-1/2';
      case '3/4':
        return 'w-3/4';
      case '1/4':
        return 'w-1/4';
      default:
        return 'w-full';
    }
  }

  hexColorValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any; } | null => {
      const isValid = this.isValidHex(control.value);
      return isValid ? null : { hexColor: { value: control.value } };
    };
  }

  isValidHex(value: string): boolean {
    return /^#([0-9A-F]{3}){1,2}$/i.test(value);
  }

  onColorPickerChange(event: any, controlName: string) {
    const color = event.target.value;
    this.form.get(controlName)?.setValue(color);
  }

  onTextInputChange(event: any, controlName: string) {
    const value = event.target.value;
    if (this.isValidHex(value)) {
      const colorPicker = document.getElementById(`${controlName}-color-picker`) as HTMLInputElement;
      if (colorPicker) {
        colorPicker.value = value;
      }
    }
  }
}
