<table class="w-full max-w-full  divide-y divide-gray-200 mt-2">
  <thead class="bg-gray-50 sticky top-0 dark:bg-dark-background-alt dark:text-white rounded-md">
    <tr>
      <th *ngFor="let header of tableConfig" scope="col"
        class="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
        [ngStyle]="{ width: header.width }">
        {{ header.label }}
      </th>
      <th *ngIf="editAction || deleteAction"
        class="pr-1 text-center text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer justify-end">
      </th>
    </tr>
  </thead>
  <tbody class="divide-y divide-gray-200">
    <tr *ngFor="let item of jsonData; let itemIndex = index; even as isEven" (click)="handleEdit(itemIndex, item)"
      class="cursor-pointer hover:bg-gray-100" [class.bg-gray-200]="isEven" [class.bg-white]="!isEven">
      <td *ngFor="let header of tableConfig; let hindex=index;" class="px-4 py-2 whitespace-nowrap text-xs"
        [ngStyle]="{ width: header.width }" [class.font-bold]="itemIndex === currentEditIndex">

        <div class="flex flex-row">
          <lucide-icon name="pencil-line" class="text-blue-500 h-4 w-4 mr-2 "
            *ngIf="showCurrentEdit && hindex===0 && itemIndex === currentEditIndex"></lucide-icon>

          <div [appTooltip]="header.truncate <= 15 ? item[header.field] : ''">
            {{ item[header.field] || (hindex === 0 ? defaultEmptyMsg : '') | truncate: header.truncate }}
          </div>
        </div>

      </td>
      <td *ngIf="deleteAction" class="pr-1 py-2 text-sm font-medium text-center justify-end relative">
        <button class="text-gray-500 hover:text-gray-800" (click)="toggleMenu(itemIndex); $event.stopPropagation()">
          <lucide-icon name="ellipsis-vertical"></lucide-icon>
        </button>
        <div *ngIf="isMenuOpen(itemIndex)"
          class="absolute right-0 z-10 mt-2 w-[120px] bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
          <div class="py-1" role="none">
            <button *ngIf="jsonData.length > 1" (click)="handleDelete(itemIndex, item); $event.stopPropagation()"
              class="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 flex flex-row" role="menuitem"
              tabindex="-1">
              <lucide-icon name="trash-2" class="mr-2"></lucide-icon> Delete
            </button>
          </div>
        </div>
      </td>

    </tr>
  </tbody>
</table>