export interface Survey {
  uuid: string;
  parentUUID?: string;
  organization?: string;
  project: string;
  title: string;
  description: string;
  questions: SurveyQuestion[];
  createDate?: number;
  accessCode?: string;
  submitted?: boolean;
  isValidCode?: boolean;
  expirationTime?: number;
  eventUUID: string;
  eventName: string;
  codeType?: string;
  inCode?: string;
  versions?: Survey[];
  isDisabled?: boolean;
  isLocked?: boolean;
  haveUpdate?: boolean;
  lastSurveyUpdate?: Survey;
  tag?: string;
  image?: string | ArrayBuffer | null;
  imageToDelete?: string[];
  assets?: Record<string, string | any>;
  changedDate?: number;
  [key: string]: string | any;
}
export interface SurveyQuestion {
  order: number;
  questionId: string;
  question: string;
  isRequired: boolean;
  type: SurveyQuestionType;
  options: string[];
  metadata: any;
}
export interface Task {
  name: string;
  type: 'data' | 'asset';
  id: string;
  buffer?: string;
}
export interface AssetMetadata {
  imageKey: string;
  imageBuffer?: ArrayBuffer;
}

export enum SurveyQuestionType {
  boolean = 'boolean',
  yesno = 'yesno',
  single = 'single-choose',
  multiple = 'multiple-choose',
  scale = 'satisfactory-scale',
  freeText = 'free-text',
  ranking = 'ranking',
  singleImage = 'single-image',
}
export enum SurveyCodeTypes {
  qrcode = 'qr-code',
  pregen = 'pregen-code',
}
export interface SurveyAnswerResult {
  code: string;
  codeType?: string;
  surveyUUID: string;
  projectUUID: string;
  eventUUID: string;
  answers: SurveyAnswer[];
}
export interface SurveyAnswer {
  questionId: string;
  answer: string | any;
  followupAnswer?: string;
}

export interface CheckCodeResponse {
  code: string;
  codeMessage: string;
  validCode: boolean;
  sendSurveyTimeEnd: number;
}

export enum SurveyErrorTypes {
  INVALID_CODE = 'INVALID-CODE',
  EXPIRED_CODE = 'EXPIRED-CODE',
  NOT_STARTED = 'NOT-STARTED',
}
export const SurveyErrorTypeMessages = {
  [SurveyErrorTypes.INVALID_CODE]: `Oops! It seems the code you entered is not recognized in our system. Please double-check the code and try again.`,
  [SurveyErrorTypes.EXPIRED_CODE]: `It looks like the code you're using has already expired. Codes have a limited validity period, so please ensure to use them before they expire.`,
  [SurveyErrorTypes.NOT_STARTED]: `Just a moment! The survey you're trying to access hasn't started yet. Please check back later when it's scheduled to begin.`,
};
