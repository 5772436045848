<!-- <div
  class="text-lg w-full font-bold mb-5 border-b border-gray-200 dark:border-gray-600 text-gray-600 dark:text-gray-200 pb-1">
  Tags:</div> -->
<div class="relative">

  <div class="flex flex-wrap gap-2">

  </div>
  <div class="flex flex-grow gap-2 items-center ">
    <div
      class="relative flex flex-grow items-center bg-white dark:bg-dark-background-alt border border-gray-300 rounded-lg overflow-hidden dark:border-none"
      matAutocompleteOrigin #origin="matAutocompleteOrigin">
      <span class="pl-2 ">

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-6 h-6 text-gray-500 dark:text-gray-400 ">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
        </svg>
      </span>
      <input type="text" placeholder="Add a tag..." [matAutocomplete]="auto" [formControl]="newTagControl"
        [matAutocompleteConnectedTo]="origin"
        class="p-2 text-gray-500 w-full flex-1 focus:outline-none focus:border-none dark:bg-dark-background-alt dark:text-gray-300"
        (keyup.enter)="addTag()" />

      <div class="flex items-center mr-2">
        <ngx-colors ngx-colors-trigger [(ngModel)]="selectedColor" (ngModelChange)="colorChanged($event)"
          class="cursor-pointer">
          <div class="w-6 h-6 rounded-full" [style.background]="selectedColor"></div>
        </ngx-colors>
      </div>

      <mat-autocomplete #auto="matAutocomplete" class="mt-1 w-full dark:bg-dark-panel" [displayWith]="displayFn">
        <mat-option *ngFor="let tag of getAvailableTags()" [value]="tag" class="w-full">
          <div class="flex flex-row w-full justify-between min-w-0 gap-3">
            <div class="flex-none h-6 w-6 rounded-lg" [ngStyle]="{'background-color': tag.color}"></div>
            <div class="flex-1 truncate dark:text-gray-50"> {{ tag.value }}</div>
          </div>
        </mat-option>
      </mat-autocomplete>

    </div>

    <button (click)="addTag()" class="primary-button">
      <lucide-angular name="plus" class="h-4 w-4"></lucide-angular>
    </button>

  </div>



  <div class="flex flex-wrap gap-2 mb-3 mt-4">
    @for(tag of tags; track $index){
    <app-tag [tag]="tag" (remove)="removeTag(tag)"></app-tag>
    }
  </div>

</div>
