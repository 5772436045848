import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss'],
})
export class PreviewModalComponent implements OnInit {
  public safeUrl!: SafeResourceUrl;
  public isLoaded: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<PreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isLoaded = false;
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }
  onIframeLoad(): void {
    setTimeout(() => {
      this.isLoaded = true;
    }, 3000);
  }
  close() {
    localStorage.removeItem(this.data.id);
    this.dialogRef.close();
  }
}
