import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { slideInAnimation } from 'src/app/shared/animations/slide-in.animation';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  animations: [slideInAnimation],
})
export class PageNotFoundComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  goBack() {
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 0);
  }
}
