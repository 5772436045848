import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-print-preview-modal',
  templateUrl: './print-preview-modal.component.html',
  styleUrl: './print-preview-modal.component.scss',
})
export class PrintPreviewModalComponent implements OnInit {
  @Input() content!: TemplateRef<any>;

  constructor(
    public dialogRef: MatDialogRef<PrintPreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
  print() {
    window.print();
  }
  close() {
    this.dialogRef.close();
  }
}
