<div class="flex flex-row items-end space-x-2" [ngClass]="{'pointer-events-none opacity-50': disabled}">
  <div class="w-1/2">
    <input type="text" [(ngModel)]="inputValue" (input)="onInputChange($event)"
      class="block w-full px-2 py-2 text-base border border-gray-300 bg-white focus:outline-none sm:text-sm rounded-md h-[36px]"
      [disabled]="disabled" />
  </div>

  <div class="w-1/2 flex flex-col">
    <span class="text-xs text-gray-500 mb-1">{{ field.metadata.optionLabel }}</span>
    <select [id]="field.fieldId" [(ngModel)]="selectValue" (change)="onSelectChange($event)"
      [attr.name]="'field-' + field.fieldId"
      class="block w-full pl-3 py-2 text-base border border-gray-300 bg-white focus:outline-none sm:text-sm rounded-md"
      [disabled]="disabled">
      <option disabled value="">{{ 'Select' }}</option>
      <ng-container *ngFor="let option of field.options; let i = index">
        <option [value]="option">{{ option }}</option>
      </ng-container>
    </select>
  </div>
</div>
