import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormField } from '../../entities/form.entity';

@Component({
  selector: 'app-field-input-text',
  templateUrl: './field-input-text.component.html',
  styleUrls: ['./field-input-text.component.scss'],
})
export class FieldInputTextComponent {
  @Input() field!: FormField;
  @Input() disabled: boolean = false;
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: any;
  }>();
  isValid!: boolean;
  errorMessage: string = '';

  handleTextareaChange(event: any) {
    const newValue = event.target.value;
    this.field.value = newValue;
    this.emitFieldChange();
  }
  onPhoneChange(value: any) {
    this.field.value = value;
    this.emitFieldChange();
  }

  getPattern(textDataType: string): string | null {
    switch (textDataType) {
      case 'email':
        return '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
      case 'phone-number':
        return '^\\+?[0-9]{10,13}$';
      case 'url':
        return 'https?://.+';
      case 'numeric':
        return '\\d+';
      default:
        return null;
    }
  }

  getIconName(textDataType: string): string {
    switch (textDataType) {
      case 'email':
        return 'at-sign';
      case 'phone-number':
        return 'phone';
      case 'url':
        return 'link';
      default:
        return '';
    }
  }
  emitFieldChange(): void {

    this.isValid = this.validateField(this.field.value);
    const type = this.field.metadata.textDataType || '';
    this.errorMessage = this.isValid ? '' : this.getErrorMessage(type);
    this.onFieldChange.emit({
      fieldId: this.field.fieldId,
      isValid: this.isValid,
      value: this.field.value,
    });
  }

  getErrorMessage(textDataType: string): string {
    switch (textDataType) {
      case 'email':
        return 'Please enter a valid email address.';
      case 'phone-number':
        return 'Please enter a valid phone number.';
      case 'url':
        return 'Please enter a valid URL.';
      case 'numeric':
        return 'Please enter numeric values only.';
      default:
        return 'Invalid input.';
    }
  }

  validateField(value: string | null): boolean {
    if (!value) return false;

    const dataType = this.field.metadata.textDataType;
    if (!dataType) return false;
    const pattern = this.getPattern(dataType);

    if (!pattern) return true;

    const regex = new RegExp(pattern);
    return regex.test(value);
  }

  getMask(textDataType: any): string | null {
    switch (textDataType) {
      case 'phone-number':
        return '000-000-0000';
      default:
        return null;
    }
  }
}
