<div class="relative mt-1 block w-full">
  <input type="text" [value]="fileName" [disabled]="disabled"
    class="pl-3 pr-10 py-2 w-full text-base border border-gray-300 bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
    placeholder="No file selected" readonly>
  <button type="button" [disabled]="disabled"
    class="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 bg-black text-white rounded-md m-1 disabled:opacity-50"
    (click)="fileInput.click()">
    Upload File
  </button>
  <input type="file" #fileInput class="hidden" (change)="fileChange($event)" [attr.accept]="extensions.join(',')"
    hidden>
</div>
<div class="text-xs text-gray-500 mt-1">
  Accepted extensions: {{ extensions.join(', ') }}
</div>
