import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormField } from '../../entities/form.entity';

@Component({
  selector: 'app-field-single-choice',
  templateUrl: './field-single-choice.component.html',
  styleUrls: ['./field-single-choice.component.scss']
})
export class FieldSingleChoiceComponent {
  @Input() field!: FormField;
  @Input() disabled: boolean = false;
  @Output() onFieldChange = new EventEmitter<{
    fieldId: string;
    isValid: boolean;
    value: any;
  }>();

  ngOnInit(): void {
    if (this.field.value === undefined || this.field.value === '') {
      this.field.value = null;
    }
  }

  handleSelectionChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.field.value = selectElement.value;
    this.emitFieldChange();
  }

  emitFieldChange(): void {
    this.onFieldChange.emit({
      fieldId: this.field.fieldId,
      isValid: this.validateField(this.field.value),
      value: this.field.value,
    });
  }

  validateField(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
  }
}
