<div class="overflow-x-auto" [ngClass]="{'pointer-events-none opacity-50': disabled}">
  <div
    class="min-w-full bg-gray-50 text-gray-700 uppercase text-xs font-bold border-l border-b border-t border-gray-300">
    <div class="flex">
      <div *ngFor="let field of table.fields" class="flex flex-col w-full bg-gray-100">
        <div class="text-center py-1 border-r border-gray-300">
          {{ field.name }}
        </div>
        <div *ngIf="field.type === 'group'" class="flex">
          <div *ngFor="let subField of field.sub_fields"
            class="flex-1 py-1 px-2 text-center border-r border-b border-gray-300 bg-gray-100">
            {{ subField.name }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let row of table.rows; trackBy: trackByFn" class="min-w-full border-b border-gray-300">
    <div class="flex">
      <div *ngFor="let field of table.fields; trackBy: trackByFn" class="flex flex-col w-full"
        [ngClass]="{'bg-gray-100': !field.editable, 'bg-white': field.editable}">
        <div *ngIf="field.type !== 'group'" class="py-1 px-2 border-r border-l border-gray-300 w-full">
          <input *ngIf="field.editable" [(ngModel)]="row[field.uuid]" (ngModelChange)="emitDataChange()"
            class="w-full bg-transparent border-none focus:outline-none" placeholder="Enter value"
            [disabled]="disabled">
          <span *ngIf="!field.editable">{{ row[field.uuid] }}</span>
        </div>
        <div *ngIf="field.type === 'group'" class="flex w-full">
          <div *ngFor="let subField of field.sub_fields; trackBy: trackByFn"
            class="flex-1 py-1 px-2 border-r border-b border-gray-300"
            [ngClass]="{'bg-gray-100': !subField.editable, 'bg-white': subField.editable}">
            <input *ngIf="subField.editable" [(ngModel)]="row[field.uuid][subField.uuid]"
              (ngModelChange)="emitDataChange()" class="w-full bg-transparent border-none focus:outline-none"
              placeholder="Enter value" [disabled]="disabled">
            <span *ngIf="!subField.editable">{{ row[field.uuid][subField.uuid] }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
