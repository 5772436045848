import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year = '';
  version = 'v2.0.0';
  ngOnInit(): void {
    this.year = new Date().getFullYear().toString();
    this.version = `version: ${environment.version}`;
  }
}
