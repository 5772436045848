<div *ngIf="badgeCount > 0" style="position: relative">
  <mat-chip (click)="toggleTooltip()" class="errors-chip">
    <div>
      <span class="label">{{ errorLabel }}</span>
      <div class="errorBadge-count">{{ badgeCount }}</div>
    </div>
  </mat-chip>

  <div
    *ngIf="showTooltip"
    class="errorBadge-tooltip"
    [ngClass]="{
      'errorBadge-tooltip-top': openFrom === 'top',
      'errorBadge-tooltip-bottom': openFrom === 'bottom'
    }"
  >
    <ul>
      @for (warning of warnings; track i; let i = $index) {
        <li><mat-icon class="warning-icon">warning</mat-icon> {{ warning }}</li>
      }
      @for (error of errors; track i; let i = $index) {
        <li><mat-icon class="error-icon">error</mat-icon> {{ error }}</li>
      }
    </ul>
  </div>
</div>

@if (badgeCount === 0 && showIsValidMsg) {
  <mat-chip class="success-chip">
    <div class="chip-content">
      <mat-icon>thumb_up_alt</mat-icon><span>{{ validMsg }}</span>
    </div>
  </mat-chip>
}
